import React from "react";
import PropTypes from "prop-types";
import LoginForm from "components/Forms/LoginForm";
import { FastField as Field } from "formik";
import FormikField from "components/Forms/FormikField";
import { opeid, createValidator, required } from "utils/validations";

const validate = createValidator({
  tenantId: [opeid, required],
  username: [required],
  password: [required],
});

export default function NextgenLoginForm({ loading, login }) {
  return (
    <LoginForm
      loading={loading}
      validations={validate}
      submit={(values) => {
        login(values);
      }}
      initialValues={{ tenantId: "", username: "", password: "" }}
      extraFields={() => (
        <Field
          component={FormikField}
          required
          mask="99999999"
          type="text"
          label="OPEID"
          placeholder="OPEID"
          name="tenantId"
          mb={1}
        />
      )}
    />
  );
}

NextgenLoginForm.propTypes = {
  loading: PropTypes.bool,
  login: PropTypes.func,
};
