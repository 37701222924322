import React from "react";
import { Segment, Header, Text, Loader } from "@jjordy/layout";
import WithStore from "components/WithStore";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import Center from "components/Elements/Center";
import { getCorpId, getQueryParams } from "redux/selectors";
import ExternalRedirect from "components/ExternalRedirect";

export default function LoginSuccess() {
  return (
    <WithStore
      selector={(state) => ({
        corpId: getCorpId(state),
        query: getQueryParams(state),
      })}
      render={({ corpId, query }) => (
        <CenterContainer pageTitle="LOGIN SUCCESS - REDIRECTING">
          <Segment attached="top" bg="primary">
            <ClientLogo corp={corpId} />
            <Header textAlign="center" fg="primary" as="h4">
              LOGIN SUCCESS - REDIRECTING
            </Header>
            <Text fg="grey" style={{ textAlign: "center" }}>
              This could take a moment please be patient...
            </Text>
            {query.nextApp && <ExternalRedirect to={query.nextApp} />}
            <Center>
              <Loader active type="three-dots" bg="primary" />
            </Center>
          </Segment>
        </CenterContainer>
      )}
    />
  );
}
