import React from "react";
import PropTypes from "prop-types";
import { loadSchool } from "redux/modules/school";
import Component from "components/Component";
import { withRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import WithStore from "components/WithStore";
import { push } from "connected-react-router";
import { Loader } from "@jjordy/layout";
import Center from "components/Elements/Center";

const getState = (state) => ({
  corpId: state.school.corpId,
  loaded: state.school.loaded,
  loading: state.school.loading,
});

function SSITE({ route, match }) {
  return (
    <WithStore
      selector={getState}
      awaiting="corpId"
      render={({ corpId, loaded, loading }, dispatch) => (
        <Component
          componentDidMount={async () => {
            if (!corpId && !loaded && match.params.school) {
              dispatch(loadSchool(match.params.school));
            } else if (loaded && !corpId) {
              dispatch(push("/404"));
            }
          }}
          render={() => {
            if (!corpId && !loaded && loading) {
              return (
                <Center>
                  <Loader active type="three-dots" />
                </Center>
              );
            }
            if (corpId && loaded) {
              return (
                <React.Fragment>
                  {corpId && loaded && renderRoutes(route.routes)}
                </React.Fragment>
              );
            }
            return null;
          }}
        />
      )}
    />
  );
}

SSITE.propTypes = {
  route: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

SSITE.loadData = async ({ store, match }) => {
  try {
    if (match.params.school) {
      await store.dispatch(loadSchool(match.params.school));
    }
  } catch (err) {
    console.log(err);
  }
};

export default withRouter(SSITE);
