import { combineReducers } from "redux";
import changePassword from "./modules/changePassword";
import forgotPassword from "./modules/forgotPassword";
import forgotUsername from "./modules/forgotUsername";
import login from "./modules/login";
import message from "./modules/message";
import migration from "./modules/migration";
import query from "./modules/query";
import profile from "./modules/profile";
import school from "./modules/school";
import signup from "./modules/signup";
import twofactor from "./modules/twofactor";
import theme from "./modules/theme";
import enumerations from "./modules/enumerations";
import verify from "./modules/verify";
import logout from "./modules/logout";
import forgotPin from "./modules/forgotPin";
import invite from "./modules/invite";
import { connectRouter } from "connected-react-router";

const rootReducer = (history) => (state, action) => {
  const appReducer = combineReducers({
    changePassword,
    enumerations,
    forgotPassword,
    forgotUsername,
    forgotPin,
    invite,
    login,
    logout,
    router: connectRouter(history),
    message,
    migration,
    query,
    profile,
    twofactor,
    theme,
    signup,
    school,
    verify,
  });
  if (action.type === "AUTH_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
