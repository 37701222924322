import React from "react";
import PropTypes from "prop-types";
import LoginForm from "components/Forms/LoginForm";

const defaultFieldLevelProps = {
  username: `Enter the username used to create your account.  
If you do not have a username, enter the email address used to create your account. 
If you have not yet created an account, click Sign Up below.`,
  password: `Enter the password associated with your account. 
If you do not remember your password, click FORGOT PASSWORD below.`,
};

export default function LegacyLoginForm({
  loading,
  login,
  school,
  extraFields,
  initialValues,
  validations,
  fieldLevelProps = defaultFieldLevelProps,
  ...rest
}) {
  return (
    <LoginForm
      loading={loading}
      validations={validations}
      initialValues={initialValues}
      extraFields={extraFields}
      fieldLevelPopups={fieldLevelProps}
      submit={(values) => {
        login({ ...values, schoolId: school });
      }}
      {...rest}
    />
  );
}

LegacyLoginForm.propTypes = {
  extraFields: PropTypes.func,
  loading: PropTypes.bool,
  login: PropTypes.func,
  initialValues: PropTypes.object,
  validations: PropTypes.func,
  school: PropTypes.string.isRequired,
  fieldLevelProps: PropTypes.object,
};

LegacyLoginForm.defaultProps = {
  extraFields: () => {},
};
