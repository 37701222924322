import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Divider, Segment, Checkbox } from "@jjordy/layout";
import { Formik, FastField as Field } from "formik";
import MaskedInput from "components/Forms/MaskedInput";
import PasswordInput from "components/Forms/PasswordInput";

import {
  createValidator,
  required,
  oneNum,
  oneCap,
  oneSpecialChar,
  minLength,
  match,
  phone,
} from "@jjordy/validation";
import FormikField from "components/Forms/FormikField";
import PhoneMessage from "components/Elements/PhoneMessage";

const validate = createValidator({
  username: [required],
  password: [required, minLength(8), oneNum, oneSpecialChar, oneCap],
  confirmPassword: [required, match("password", "Passwords")],
  phone: [required, phone],
});

export default function MigrationForm({ submit, loading }) {
  return (
    <Formik
      onSubmit={(values) => {
        let payload = values;
        if (values.phone) {
          const cleanedPhone = values.phone.replace(/\(|\)| /g, "");
          payload = Object.assign({}, values, {
            phone: cleanedPhone,
          });
        }
        submit(payload);
      }}
      initialValues={{
        username: "",
        password: "",
        confirmPassword: "",
        phone: "",
        SMSEnroll: false,
      }}
      validate={(values) => {
        const errors = validate(values);
        return errors;
      }}
    >
      {({
        values,
        handleSubmit,
        handleBlur,
        touched,
        errors,
        handleChange,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <Field
            component={FormikField}
            label="Username"
            type="text"
            name="username"
            required
            placeholder="********"
          />
          <Field
            component={FormikField}
            label="Password"
            As={PasswordInput}
            type="password"
            name="password"
            required
            placeholder="********"
          />
          <Field
            component={FormikField}
            label="Confirm Password"
            As={PasswordInput}
            type="password"
            name="confirmPassword"
            placeholder="********"
            required
          />
          <Field
            component={FormikField}
            As={MaskedInput}
            label="Phone Number"
            name="phone"
            mask="(999) 999 9999"
            placeholder="Enter your phone number"
          />
          <Segment bg="primary" my={2}>
            <Field
              component={FormikField}
              As={Checkbox}
              bg="primary"
              name="SMSEnroll"
              label="Would you like to enroll in SMS/Text Notifications?"
              onChange={(v) => setFieldValue("SMSEnroll", v)}
            />
          </Segment>
          <Divider my={1} />
          <PhoneMessage />
          <Button
            type="submit"
            fluid
            content="Update Account"
            bg="primary"
            mt={1}
            rounded
          />
        </Form>
      )}
    </Formik>
  );
}

MigrationForm.propTypes = {
  submit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
