import { connect } from "react-redux";

const defaultSelector = (state) => state;

const WithStore = ({ children, state, dispatch, render, awaiting }) => {
  const handleRender = () => {
    if (children) {
      return children(state, dispatch);
    } else if (render) {
      return render(state, dispatch);
    } else {
      return null;
    }
  };
  if (awaiting) {
    if (state[awaiting] !== undefined || state[awaiting] !== null) {
      return handleRender();
    } else {
      return null;
    }
  } else {
    return handleRender();
  }
};

export default connect(
  (state, { selector = defaultSelector }) => ({ state: selector(state) }),
  (dispatch) => ({ dispatch })
)(WithStore);
