import React from "react";
import PropTypes from "prop-types";
import Raven from "raven-js";
import ErrorFallback from "./ErrorFallback";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, error, info });
    // You can also log the error to an error reporting service
    if (!__DEV__) {
      // eslint-disable-line
      Raven.captureException(error, { extra: info });
    }
  }

  render() {
    const { error, info } = this.state;
    if (this.state.hasError && error) {
      // You can render any custom fallback UI
      return (
        <ErrorFallback
          componentStack={info ? info.componentStatck : ""}
          error={error}
        />
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};
