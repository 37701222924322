import React from "react";
import PropTypes from "prop-types";
import SignupForm from "components/Forms/SignupForm";
import { FastField as Field } from "formik";
import { Checkbox } from "@jjordy/layout";
import FormikField from "components/Forms/FormikField";
import validate from "./validations";
import Center from "components/Elements/Center";
import Legal from "components/Legal";
import Flex from "components/Elements/Flex";

const NextgenSignup = ({ countries, states, onSubmit, loading }) => {
  if (countries && states) {
    return (
      <SignupForm
        loading={loading}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          username: "",
          password: "",
          confirmPassword: "",
          tenantId: "",
          claims: [
            { userId: null, claimType: "groups", claimValue: "billing" },
            {
              userId: null,
              claimType: "userTypes",
              claimValue: "datacheck_billing",
            },
            { userId: null, claimType: "groups", claimValue: "datacheck" },
            {
              userId: null,
              claimType: "userTypes",
              claimValue: "datacheck_administrator",
            },
          ],
        }}
        validations={(values) => {
          const errors = validate(values);
          return errors;
        }}
        submit={onSubmit}
        countries={countries}
        states={states}
        extraFields={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <React.Fragment>
            <Field component={FormikField} name="tenantId" label="OPEID" />
            <Legal />
            <Flex justify="flex-end" align="center">
              <a
                href="http://www.globalfas.com/help/datacheck"
                target="_blan"
                rel="noopener"
              >
                <small>Need Help?</small>
              </a>
            </Flex>
            <Center>
              <Checkbox
                mb={2}
                bg="primary"
                name="agreeTOS"
                label="I Agree to the terms and conditions"
                onChange={(v) => setFieldValue("agreeTOS", v)}
              />
            </Center>
          </React.Fragment>
        )}
      />
    );
  }

  return null;
};
NextgenSignup.propTypes = {
  countries: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  states: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
};

export default NextgenSignup;
