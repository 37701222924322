import React from "react";
import PropTypes from "prop-types";
import { Formik, FastField as Field } from "formik";
import FormikField from "components/Forms/FormikField";
import MaskedInput from "components/Forms/MaskedInput";
import { Form, Button, Divider, Icon, Checkbox } from "@jjordy/layout";
import { createValidator, phone } from "@jjordy/validation";
import PhoneMessage from "components/Elements/PhoneMessage";

const validate = createValidator({
  phoneNumber: [phone],
});

export default function MyProfileForm({ submit, loading, initialValues }) {
  return (
    <Formik
      onSubmit={submit}
      enableReinitialize
      validate={(values) => {
        const errors = validate(values);
        return errors;
      }}
      initialValues={{
        phoneNumber: initialValues.phoneNumber || "",
        SMSEnrolled: initialValues.SMSEnrolled || false,
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <Field
            component={FormikField}
            As={MaskedInput}
            mask="(999) 999 9999"
            label="Phone Number"
            name="phoneNumber"
          />
          <Field
            component={Checkbox}
            name="SMSEnrolled"
            bg="primary"
            mb={1}
            label="SMS/Text Notifications"
            checked={values.SMSEnrolled}
            onChange={(v) => setFieldValue("SMSEnrolled", v)}
            message={`
              Would you like to receive your Financial Aid notifications via SMS?
              This will not affect 2 factor notifications.
            `}
          />
          <PhoneMessage />
          <Divider hidden my={2} />
          <Button type="submit" disabled={loading} bg="primary" rounded fluid>
            <Button.Content>SAVE PROFILE</Button.Content>
            <Button.Icon>
              <Icon icon="chevron_right" bg="white" />
            </Button.Icon>
          </Button>
        </Form>
      )}
    </Formik>
  );
}

MyProfileForm.propTypes = {
  initialValues: PropTypes.object,
  submit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
