import { handleActions, createAction } from "redux-actions";
import { push } from "connected-react-router";
import http from "../http";

const forgotPasswordReq = createAction("FORGOT_PASSWORD_REQ");
const forgotPasswordSuc = createAction("FORGOT_PASSWORD_SUC");
const forgotPasswordFail = createAction("FORGOT_PASSWORD_FAIL");

const api = http();

export const forgotPassword = (values) => {
  return async (dispatch) => {
    dispatch(forgotPasswordReq());
    try {
      const { data } = await api.post("/forgot-password", values);
      dispatch(forgotPasswordSuc(data));
      dispatch(push("/forgot-password/success"));
    } catch (err) {
      dispatch(forgotPasswordFail(err));
      if (err.response && err.response.status === 400) {
        throw new Error("Email address not found");
      }
    }
  };
};

const initialState = {
  data: null,
  error: null,
  loading: false,
};

export default handleActions(
  {
    [forgotPasswordReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [forgotPasswordSuc]: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false,
    }),
    [forgotPasswordFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
  initialState
);
