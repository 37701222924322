import { handleActions, createAction } from "redux-actions";
import http from "../http";
import { push } from "connected-react-router";

const initiateTwoFactorReq = createAction("INITIATE_TWO_FACTOR_REQ");
const initiateTwoFactorSuc = createAction("INITIATE_TWO_FACTOR_SUC");
const initiateTwoFactorFail = createAction("INITIATE_TWO_FACTOR_FAIL");

const verifyTwoFactorReq = createAction("VERIFY_TWO_FACTOR_REQ");
const verifyTwoFactorSuc = createAction("VERIFY_TWO_FACTOR_SUC");
const verifyTwoFactorFail = createAction("VERIFY_TWO_FACTOR_FAIL");

const setProviderType = createAction("SET_TWO_FACTOR_PROVIDER_TYPE");

const api = http();

export const initiateTwoFactor = (userId, type) => {
  return async (dispatch) => {
    dispatch(initiateTwoFactorReq());
    dispatch(setProviderType(type));
    try {
      const { data } = await api.post("/two-factor/initiate", { userId, type });
      dispatch(initiateTwoFactorSuc(data));
      dispatch(push("/two-factor/enter-code"));
    } catch (err) {
      dispatch(initiateTwoFactorFail(err));
    }
  };
};

export const verifyTwoFactor = ({ userId, token, trustComputer }) => {
  return async (dispatch, getState) => {
    dispatch(verifyTwoFactorReq());
    const providerType = getState().twofactor.providerType;
    try {
      await api.post("/two-factor/verify", {
        userId,
        providerType,
        token,
        trustComputer,
      });
      dispatch(verifyTwoFactorSuc());
      return;
    } catch (err) {
      dispatch(verifyTwoFactorFail(err.response));
      if (err.response.status === 401) {
        throw new Error("SOMETHING WENT WRONG");
      }
    }
  };
};

const initialState = {
  data: {},
  error: null,
  loading: false,
  providerType: null,
};

export default handleActions(
  {
    [initiateTwoFactorReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [initiateTwoFactorSuc]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
    [initiateTwoFactorFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [verifyTwoFactorReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [verifyTwoFactorSuc]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),
    [verifyTwoFactorFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [setProviderType]: (state, { payload }) => ({
      ...state,
      providerType: payload,
    }),
  },
  initialState
);
