import React, { useState } from "react";
import PropTypes from "prop-types";
import CenterContainer from "components/Elements/CenterContainer";
import {
  Segment,
  Header,
  Loader,
  Menu,
  Text,
  Button,
  Flex,
  Icon,
} from "@jjordy/layout";
import { getAttachState } from "utils";
import ClientLogo from "components/Elements/ClientLogo";
import MyProfileForm from "./MyProfileForm";
import UpdatePasswordForm from "./UpdatePasswordForm";
import WithStore from "components/WithStore";
import Center from "components/Elements/Center";
import Component from "components/Component";
import {
  getProfile,
  updateProfile,
  updatePassword,
  redirectDependent,
} from "redux/modules/profile";
import Redirect from "components/Redirect";
import { cleanMask } from "@jjordy/validation";
import { profileAuthorized } from "redux/selectors";
import Cookie from "universal-cookie";
import Link from "components/QueryStateLink";
const cookie = new Cookie();

const getState = (state) => ({
  token: state.login.token,
  profile: state.profile.data,
  loading: state.profile.loading,
  redirectURL: state.redirectURL,
  authorized: profileAuthorized(state),
});

export function MyProfile() {
  const [page, setPage] = useState("general");
  const [redirect, setRedirect] = useState(null);
  return (
    <CenterContainer pageTitle="My Profile">
      <Segment bg="primary" mt={2} clearing>
        <ClientLogo />
        <Header as="h5" fg="primary" textAlign="center">
          MY PROFILE
        </Header>
        <WithStore
          selector={getState}
          render={(
            { token, profile, loading, authorized, redirectURL },
            dispatch
          ) => (
            <div>
              <Menu secondary>
                <Menu.Item
                  active={page === "general"}
                  onClick={() => setPage("general")}
                >
                  <Text strong fg="grey" p={0} m={0}>
                    GENERAL
                  </Text>
                </Menu.Item>
                <Menu.Item
                  active={page === "password"}
                  onClick={() => setPage("password")}
                >
                  <Text strong fg="grey" p={0} m={0}>
                    CHANGE PASSWORD
                  </Text>
                </Menu.Item>
                {profile &&
                  profile.multiAccountSelection &&
                  profile.multiAccountSelection.length > 0 && (
                    <Menu.Item
                      active={page === "dependents"}
                      onClick={() => setPage("dependents")}
                    >
                      <Text strong fg="grey" p={0} m={0}>
                        DEPENDENTS
                      </Text>
                    </Menu.Item>
                  )}
              </Menu>
              <Component
                componentDidMount={() => {
                  if (token) {
                    if (!profile && !loading) {
                      dispatch(getProfile(token));
                    }
                  }
                  const redirect = cookie.get("SSITERedirectURL");

                  if (
                    redirect &&
                    (redirect.includes(".globalvfs.com") ||
                      redirect.includes(".studentaidprocess.com"))
                  ) {
                    setRedirect(redirect);
                  }
                }}
                render={() => {
                  if (!token || !authorized) {
                    return <Redirect to="/" />;
                  }
                  if (profile && page === "general") {
                    return (
                      <MyProfileForm
                        loading={loading}
                        submit={(values) => {
                          const payload = Object.assign({}, profile, values, {
                            phoneNumber: cleanMask(values.phoneNumber),
                          });
                          dispatch(updateProfile(payload, token));
                        }}
                        initialValues={profile}
                      />
                    );
                  }

                  if (profile && page === "password") {
                    return (
                      <UpdatePasswordForm
                        loading={loading}
                        submit={(values) =>
                          dispatch(updatePassword(values, token))
                        }
                      />
                    );
                  }

                  if (
                    profile &&
                    page === "dependents" &&
                    profile.multiAccountSelection &&
                    profile.multiAccountSelection.length > 0
                  ) {
                    return (
                      <div>
                        {profile.multiAccountSelection.map((item, id) => (
                          <Segment
                            loading={loading}
                            attached={getAttachState(
                              id,
                              profile.multiAccountSelection.length
                            )}
                          >
                            <Flex align="center" justify="space-between">
                              <Flex align="center">
                                <Icon
                                  icon="user"
                                  size={2}
                                  bg="primary"
                                  mr={2}
                                />
                                <Text fg="grey" m={0}>
                                  {item.firstName} {item.lastName}
                                </Text>
                              </Flex>
                              <Button
                                bg="primary"
                                ignoreQS
                                as={Link}
                                to={`/my-profile/verify-dependent/${item.studentId}`} /* onClick={() => dispatch(redirectDependent(token, item.studentId))} */
                              >
                                Verify & Login
                              </Button>
                            </Flex>
                          </Segment>
                        ))}
                      </div>
                    );
                  }
                  if (loading) {
                    return (
                      <Center>
                        <Loader type="three-dots" active bg="primary" />
                      </Center>
                    );
                  }
                  return null;
                }}
              />
              <br />
              {redirect && (
                <Button
                  as="a"
                  href={redirect}
                  content="BACK TO FINANCIAL AID PROCESS"
                />
              )}
              {!redirect && (
                <Button onClick={() => window.history.go(-2)} fluid>
                  BACK TO FINANCIAL AID PROCESS
                </Button>
              )}
            </div>
          )}
        />
      </Segment>
    </CenterContainer>
  );
}

MyProfile.propTypes = {
  match: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
  setPage: PropTypes.func.isRequired,
};

MyProfile.loadData = async ({ store, match }) => {
  const token = store.getState().login.token;
  try {
    if (!token) {
      await store.dispatch(getProfile(token));
    }
  } catch (err) {
    console.log(err);
  }
};

export default MyProfile;
