import {
  minLength,
  match,
  createValidator,
  email,
  maxLength,
  required,
  phone,
  oneSpecialChar,
  zipcode,
  dateMMDDYYYY,
  oneCap,
  oneNum,
} from "@jjordy/validation";

function oneLowerCase(value) {
  if (!/[a-z]/.test(value)) {
    return "Must have one lowercase letter.";
  }
}

function studentFirstName(value) {
  if (!/^[a-zA-Z0-9 '.-]+$/.test(value)) {
    return "Name contains invalid characters.";
  }
}

function studentLastName(value) {
  if (!/^[a-zA-Z0-9 '.-]+$/.test(value)) {
    return "Name contains invalid characters.";
  }
}

function onlySpaceAfterWord(value) {
  if (!/^\s{0,3}[a-zA-Z0-9,'.-]+(\s{0,3}[a-zA-Z0-9,'.-])*$/.test(value)) {
    return "Invalid Characters.";
  }
}

const validator = createValidator({
  firstName: [required, studentFirstName, onlySpaceAfterWord],
  lastName: [required, studentLastName, onlySpaceAfterWord],
  password: [
    required,
    oneCap,
    oneSpecialChar,
    oneLowerCase,
    minLength(8),
    oneNum,
  ],
  confirmPassword: [
    required,
    oneSpecialChar,
    oneCap,
    oneLowerCase,
    minLength(8),
    match("password", "Passwords"),
    oneNum,
  ],
  email: [required, email],
  username: [required],
  ssn: [required, minLength(11), maxLength(11)], // this is masked so we add 2 for dashes they will be removed on submit
  phone: [required, phone],
  dob: [required, dateMMDDYYYY],
  startDate: [required],
  program: [required],
  address1: [required],
  bestTimeToCall: [required],
  city: [required],
  state: [required],
  zip: [required, zipcode],
  country: [required],
});

export default validator;
