import {
  createValidator,
  required,
  email,
  minLength,
  integer,
  maxLength,
  match,
  oneNum,
  oneCap,
  oneSpecialChar,
} from "@jjordy/validation";

const validation = createValidator({
  email: [required, email],
  username: [required],
  password: [
    required,
    minLength(8),
    maxLength(25),
    oneNum,
    oneSpecialChar,
    oneCap,
  ],
  confirmPassword: [required, match("password", "Passwords")],
  firstName: [required],
  lastName: [required],
  agreeTOS: [required],
  tenantId: [required, minLength(8), maxLength(8), integer],
});

export default validation;
