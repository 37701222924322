import { handleActions, createAction } from "redux-actions";

export const createMessage = createAction("CREATE_SYSTEM_MESSAGE");
export const removeMessage = createAction("REMOVE_SYSTEM_MESSAGE");

const initialState = {
  content: null,
  header: null,
  icon: null,
  color: null,
  children: null,
};

export default handleActions(
  {
    [createMessage]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [removeMessage]: (state) => ({
      ...state,
      ...initialState,
    }),
  },
  initialState
);
