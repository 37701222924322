import React, { useState } from "react";
import { Text, Flex, Header, Button } from "@jjordy/layout";
import axios from "axios";
import { Link, Switch, Route } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { FadeIn } from "components/Elements/Animation";
import Progress from "./Progress";
import styled from "styled-components";
import Information from "./Information";
import useMediaQuery from "hooks/useMediaQuery";
import { useLocation } from "../../../hooks/useRouter";
import NetworkError from "../../../components/Errors/NetworkError";

const Main = styled.div`
  width: 50%;
  flex: 1 1 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Side = styled.div`
  width: 25%;
  min-width: 200px;
  margin-right: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Right = styled.div`
  width: 25%;
  margin-left: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default function Migrate() {
  const { navigate } = useLocation();
  // new way
  // setup local state.
  const [networkError, setNetworkError] = useState(null);
  const [networkLoading, setNetworkLoading] = useState(false);
  // migration progress
  const [migrationStep, setMigrationStep] = useState(1);
  // step 1
  const [step1Data, setStep1Data] = useState(null);
  // step 2
  const [step2Data, setStep2Data] = useState(null);
  const isMobile = useMediaQuery("(max-width: 400px)");

  const initiateMigration = async (migrateFormValues, formikActions) => {
    formikActions.setSubmitting(true);
    try {
      setNetworkError(null);
      setNetworkLoading(true);
      const { data } = await axios.post(
        "/api/legacy/gcore/migrate",
        migrateFormValues
      );
      setNetworkLoading(false);
      formikActions.setSubmitting(false);
      setStep1Data({ ...migrateFormValues, ...data });
      setMigrationStep(2);
      navigate(`/gcore/migrate/2`);
    } catch (err) {
      setNetworkLoading(false);
      formikActions.setSubmitting(false);
      setNetworkError(err);
    }
  };

  const finalizeMigration = async (finalizeMigrationValues, formikActions) => {
    formikActions.setSubmitting(true);
    try {
      setNetworkError(null);
      setNetworkLoading(true);
      const { data } = await axios.post(
        "/api/legacy/gcore/migrate/finalize",
        finalizeMigrationValues
      );
      setNetworkLoading(false);
      formikActions.setSubmitting(false);
      setStep2Data({ ...finalizeMigrationValues, ...data });
      setMigrationStep(3);
      navigate(`/gcore/migrate/3`);
    } catch (err) {
      setNetworkLoading(false);
      formikActions.setSubmitting(false);
      setNetworkError(err);
    }
  };
  return (
    <React.Fragment>
      <Flex align="flex-start" wrap={isMobile ? "wrap" : null}>
        <Side>
          <Progress step={migrationStep} setStep={setMigrationStep} />
        </Side>
        <Main>
          <Header as="h4" fg="grey">
            Migrate GlobalCORE Staff Portal Account
            <br />
          </Header>
          <Switch>
            <Route
              path={`/gcore/migrate`}
              exact
              render={() => (
                <FadeIn>
                  <Text strong>Step 1 of 3</Text>
                </FadeIn>
              )}
            />
            } />
            <Route
              path={`/gcore/migrate/2`}
              exact
              render={() => (
                <Flex justify="space-between">
                  <Text strong>Step 2 of 3</Text>
                  <Button
                    bg="red"
                    icon="chevron_left"
                    as={Link}
                    to="/gcore/migrate"
                  >
                    GO BACK
                  </Button>
                </Flex>
              )}
            />
            } />
            <Route
              path={`/gcore/migrate/3`}
              exact
              render={() => (
                <Flex justify="space-between">
                  <Text strong>Step 3 of 3</Text>
                </Flex>
              )}
            />
            } />
          </Switch>
          <NetworkError networkError={networkError} />
          <Switch>
            <Route
              path={`/gcore/migrate`}
              exact
              render={() => (
                <FadeIn>
                  <Step1
                    onSubmit={initiateMigration}
                    loading={networkLoading}
                  />
                </FadeIn>
              )}
            />
            <Route
              path={`/gcore/migrate/2`}
              render={() => (
                <FadeIn>
                  <Step2
                    onSubmit={finalizeMigration}
                    loading={networkLoading}
                    initialValues={step1Data}
                    goBack={() => setMigrationStep(1)}
                  />
                </FadeIn>
              )}
            />
            <Route
              path={`/gcore/migrate/3`}
              render={() => (
                <FadeIn>
                  <Step3 />
                </FadeIn>
              )}
            />
          </Switch>
        </Main>
        <Right>
          <Switch>
            <Route
              path={`/gcore/migrate`}
              exact
              render={() => (
                <FadeIn>
                  <Information />
                </FadeIn>
              )}
            />
          </Switch>
        </Right>
      </Flex>
    </React.Fragment>
  );
}
