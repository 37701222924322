import React from "react";
import PropTypes from "prop-types";
import { Form, Divider, Button, Icon } from "@jjordy/layout";
import { Formik, FastField as Field } from "formik";
import FormikField from "./FormikField";
import PasswordInput from "./PasswordInput";
import { withRouter } from "react-router-dom";

const createIntialValues = (v) => Object.assign({}, {}, v);

const hasInitialValue = (obj, prop) => {
  if (obj && typeof obj[prop] !== "undefined") {
    return true;
  }
  return false;
};

const createValidations = (validations) => (values) => {
  const errors = {};
  if (validations) {
    return validations(values, errors);
  } else {
    return errors;
  }
};

export function LoginForm({
  submit,
  initialValues,
  validations,
  extraFields,
  loading,
  location,
  loginText = "LOGIN",
  labelOverrides,
  fieldLevelPopups,
}) {
  return (
    <Formik
      onSubmit={submit}
      validate={createValidations(validations)}
      initialValues={createIntialValues(initialValues)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          {hasInitialValue(initialValues, "username") && (
            <Field
              component={FormikField}
              required
              title={fieldLevelPopups && fieldLevelPopups.username}
              type="text"
              autoComplete="username"
              label="USERNAME"
              name="username"
              placeholder="Username"
            />
          )}
          {hasInitialValue(initialValues, "email") && (
            <Field
              component={FormikField}
              required
              type="email"
              title={fieldLevelPopups && fieldLevelPopups.email}
              label={
                labelOverrides && labelOverrides.email
                  ? labelOverrides.email
                  : "EMAIL"
              }
              name="email"
              placeholder="Email"
            />
          )}
          {hasInitialValue(initialValues, "password") && (
            <Field
              component={FormikField}
              required
              type="password"
              As={PasswordInput}
              title={fieldLevelPopups && fieldLevelPopups.password}
              label="PASSWORD"
              name="password"
              placeholder="********"
            />
          )}
          {extraFields({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          })}
          <Divider hidden my={2} />
          <Button
            type="submit"
            as="button"
            fluid
            disabled={loading}
            bg="primary"
            rounded
          >
            <Button.Content>{loginText}</Button.Content>
            <Button.Icon>
              <Icon icon="chevron_right" bg="white" />
            </Button.Icon>
          </Button>
        </Form>
      )}
    </Formik>
  );
}

LoginForm.propTypes = {
  loading: PropTypes.bool,
  location: PropTypes.object,
  submit: PropTypes.func.isRequired,
  extraFields: PropTypes.func,
  initialValues: PropTypes.object,
  validations: PropTypes.func,
  loginText: PropTypes.string,
  labelOverrides: PropTypes.object,
  fieldLevelPopups: PropTypes.object,
};

LoginForm.defaultProps = {
  extraFields: () => {},
};

export default withRouter(LoginForm);
