import React from "react";
import PropTypes from "prop-types";
import MigrateForm from "./Form";
import { Text } from "@jjordy/layout";

export default function Step1({ loading, onSubmit }) {
  return (
    <div>
      <Text fg="grey">
        Enter your existing GlobalCORE username and password. On the next screen
        you will be able to update your username and email if needed.
      </Text>
      <MigrateForm onSubmit={onSubmit} loading={loading} />
    </div>
  );
}

Step1.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};
