import React from "react";
import { Text } from "@jjordy/layout";
import { Link } from "react-router-dom";

export default function Information() {
  return (
    <div>
      <Text fg="grey">
        If you <strong>havent migrated</strong> your account.
      </Text>
      <Text small>
        Login with your existing GlobalCORE account to the left and follow the
        steps to create a new account.
      </Text>
      <Text fg="grey">
        If you <strong>have migrated</strong> your account.
      </Text>
      <Link to="/">
        <small>Click here to login</small>
      </Link>
    </div>
  );
}
