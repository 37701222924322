export default function createMiddleware(dsn, cfg = {}) {
  if (typeof window !== "undefined" && !__DEV__) {
    const Raven = require("raven-js");

    if (!Raven.isSetup()) {
      if (!dsn) {
        // Skip this middleware if there is no DSN.
        console.error("[redux-raven-middleware] Sentry DSN required.");
        return (store) => (next) => (action) => {
          next(action);
        };
      }
      Raven.config(dsn, cfg).install();
    }

    return (store) => (next) => (action) => {
      try {
        Raven.captureBreadcrumb({
          data: { redux: action.type },
        });
        return next(action);
      } catch (err) {
        console.error(
          "[redux-raven-middleware] Reporting error to Sentry:",
          err
        );
        // Send the report.
        Raven.captureException(err, {
          extra: {
            action: action,
            state: store.getState(),
          },
        });
      }
    };
  } else {
    return (store) => (next) => (action) => {
      return next(action);
    };
  }
}
