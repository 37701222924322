import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import RavenMiddleware from "./middlewares/raven";
import { routerMiddleware } from "connected-react-router";

const DSN = typeof window === "undefined" ? process.env.SENTRY : __SENTRY__;
const VERSION =
  typeof window === "undefined" ? process.env.VERSION : __VERSION__;

const vanillaPromise = (store) => (next) => (action) => {
  if (typeof action.then !== "function") {
    return next(action);
  }
  return Promise.resolve(action).then(store.dispatch);
};

let composeEnhancers = compose;

const configureStore = ({ initialState, history, isDev }) => {
  let store;
  const withRouter = routerMiddleware(history);
  if (isDev && typeof window !== "undefined") {
    const composeWithDevToolsExtension =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    if (typeof composeWithDevToolsExtension === "function") {
      composeEnhancers = composeWithDevToolsExtension;
    }
    const enhancer = composeEnhancers(
      applyMiddleware(vanillaPromise),
      applyMiddleware(thunk),
      applyMiddleware(withRouter)
    );
    store = createStore(rootReducer(history), initialState, enhancer);
  } else {
    store = createStore(
      rootReducer(history),
      initialState,
      compose(
        applyMiddleware(vanillaPromise),
        applyMiddleware(RavenMiddleware(DSN, { release: VERSION })),
        applyMiddleware(thunk),
        applyMiddleware(withRouter)
      )
    );
  }
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./rootReducer", () => {
      const nextRootReducer = require("./rootReducer").default;
      store.replaceReducer(nextRootReducer(history));
    });
  }

  return store;
};

export default configureStore;
