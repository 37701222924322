import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import {
  Segment,
  Header,
  Text,
  Divider,
  Icon,
  Button,
  Dimmer,
  Box,
  Grid,
} from "@jjordy/layout";
import { login } from "redux/modules/login";
import WithStore from "components/WithStore";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import LegacyLoginForm from "./LegacyLoginForm";
import ErrorDisplay from "components/Errors/ErrorDisplay";
import styled from "styled-components";
import Link from "components/QueryStateLink";
import { createValidator, required } from "@jjordy/validation";
import { getCorpId, getSchoolId } from "redux/selectors";
import { useLocation } from "hooks/useRouter";
import { FaUserGraduate, FaUserTie } from "react-icons/fa";
const validate = createValidator({
  username: [required],
  password: [required],
});

const ContentContainer = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Extras = styled.div`
  width: 70%;
  margin-left: 1rem;
  @media (max-width: 768px) {
    margin-left: 0rem;
    width: 100%;
  }
`;

const LoginContainer = styled.div`
  width: 25%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const getState = (state) => ({
  corpId: getCorpId(state),
  name: state.school.name,
  loading: state.login.loading,
  loaded: state.school.loaded,
  isRedirect: state.school.useReferal,
  schoolId: getSchoolId(state),
});

export default function StudentLogin({ match }) {
  const [signupModal, setSignupModal] = useState(false);
  const { navigate } = useLocation();
  return (
    <CenterContainer pageTitle="STUDENT LOGIN" width={1150}>
      <WithStore
        selector={getState}
        awaiting="corpId"
        render={({ name, loading, isRedirect, schoolId }, dispatch) => (
          <React.Fragment>
            <Helmet title={`${name} Login`} />
            <Segment attached="top" bg="primary">
              <ClientLogo centered={false} />
              <Header fg="primary" as="h5">
                {name} LOGIN
              </Header>
            </Segment>
            <Segment attached="bottom">
              <ContentContainer>
                <LoginContainer>
                  <ErrorDisplay>
                    {({ setError }) => (
                      <LegacyLoginForm
                        loading={loading}
                        validations={(values, errors) => {
                          const e = validate(values);
                          return Object.assign({}, errors, e);
                        }}
                        school={match.params.school}
                        initialValues={{
                          username: "",
                          password: "",
                        }}
                        login={(values) =>
                          dispatch(
                            login("/legacy/ssite/student/login", values)
                          ).catch((err) =>
                            setError({ errorMessage: err.message })
                          )
                        }
                      />
                    )}
                  </ErrorDisplay>
                  <Divider />
                  {!isRedirect && (
                    <Button
                      as="button"
                      fluid
                      rounded
                      bg="primary"
                      mb={1}
                      onClick={() => setSignupModal(true)}
                    >
                      <Button.Content>SIGN UP</Button.Content>
                      <Button.Icon>
                        <Icon icon="chevron_right" bg="white" />
                      </Button.Icon>
                    </Button>
                  )}
                  <Divider />
                  <Button as={Link} to={"/forgot-password"}>
                    FORGOT PASSWORD
                  </Button>
                  <Button as={Link} to="/forgot-username" mt={1}>
                    FORGOT USERNAME
                  </Button>
                  <Button as={Link} to={"/parent-login"} mt={1} bg="success">
                    PARENT LOGIN
                  </Button>
                </LoginContainer>
                <Extras>
                  <Header fg="primary" dividing as="h4" m={0}>
                    Welcome to the Online Financial Aid Office
                  </Header>
                  <Text fg="grey">
                    This website is a resource for financial aid information and
                    processing your request for federal student aid for current
                    and prospective students. To gain access to this system,
                    complete one of the following:
                  </Text>
                  <Divider hidden />
                  <Header fg="grey" as="h6">
                    If you ALREADY HAVE AN ACCOUNT
                  </Header>
                  <Text fg="grey">
                    If you already have an account, login by entering your email
                    address or username and password in the boxes to the left.
                    Click LOGIN to gain access to your account information. If
                    you have forgotten your password, click{" "}
                    <Link to="/forgot-password" fg="secondary">
                      Forgot Password.
                    </Link>{" "}
                    If you have had a change to the email address you used when
                    creating your account, please contact your Financial Aid
                    Administrator.
                  </Text>
                  <Header as="h6" fg="grey">
                    If you are the PARENT
                  </Header>
                  <Text fg="grey">
                    If you are the parent of a student who has already
                    registered, click{" "}
                    <Link to="/parent-login" fg="secondary">
                      Parent Login
                    </Link>{" "}
                    to gain access to the Parent Login page where you will enter
                    your login information.
                  </Text>
                  {!isRedirect && (
                    <Header as="h6" fg="grey">
                      If you need to CREATE A NEW ACCOUNT
                    </Header>
                  )}
                  {!isRedirect && (
                    <Text fg="grey">
                      All users must create a new account to gain access to the
                      system. Click{" "}
                      <Link to="/signup" fg="secondary">
                        Sign Up
                      </Link>{" "}
                      on the left to begin the process.
                    </Text>
                  )}
                  <Header as="h6" fg="grey">
                    Having TROUBLE LOGGING IN?
                  </Header>
                  <Text fg="grey">
                    Please ensure that the email address/username that you are
                    entering is the email address/username used to create your
                    account.
                  </Text>
                  <Text fg="grey">
                    If you do not have a username, enter the email address used
                    to create your account.
                  </Text>
                  <Text fg="grey">
                    If you do not recall your username, please click{" "}
                    <Link to="/forgot-username" fg="secondary">
                      Forgot Username.
                    </Link>
                  </Text>
                  <Text fg="grey">
                    If you do not recall the email address that was used to
                    create your account, please contact your Financial Aid
                    Administrator.
                  </Text>
                </Extras>
              </ContentContainer>
            </Segment>
            {signupModal && (
              <>
                <Dimmer dark active={signupModal}>
                  <Box bg="#fff" py={3} px={2}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "1rem 0rem 1rem 0rem",
                      }}
                    >
                      <ClientLogo size="medium" />
                      <Button
                        icon="close"
                        title="Close this menu"
                        bg="error"
                        onClick={() => setSignupModal(false)}
                      />
                    </div>

                    <Grid width="50%" gap={128}>
                      <div>
                        <div
                          style={{
                            minHeight: 300,
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Header as="h2" fg="grey">
                            Are you a student?
                          </Header>
                          <FaUserGraduate
                            style={{ width: 48, height: 48, color: "#6C757D" }}
                          />
                          <Text>
                            If you are a <strong>STUDENT</strong> and need to{" "}
                            <strong>CREATE A NEW ACCOUNT</strong>, click below
                            to create your Student username/password.
                          </Text>
                          <Text>
                            If you are a <strong>STUDENT</strong> and already
                            have a username/password, please return to the LOGIN
                            page (by closing this menu) and then click the LOGIN
                            button to log into your existing Student account.
                          </Text>
                        </div>
                        <Button
                          fluid
                          size={0.5}
                          bg="primary"
                          style={{ fontSize: 22 }}
                          onClick={() => navigate(`/ssite/${schoolId}/signup`)}
                        >
                          I would like to signup as a student
                        </Button>
                      </div>
                      <div>
                        <div
                          style={{
                            minHeight: 300,
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Header as="h2" fg="grey">
                            Are you the parent of a student?
                          </Header>
                          <FaUserTie
                            style={{ width: 48, height: 48, color: "#6C757D" }}
                          />
                          <Text>
                            If you are the <strong>PARENT</strong> of a student
                            who has already registered and have been provided
                            with a 4 digit PIN to access the Parent account,
                            click below to create your Parent username/password.
                          </Text>
                          <Text>
                            If you are the <strong>PARENT</strong> and already
                            have a username/password, please return to the LOGIN
                            page (by closing this menu) and then click the
                            PARENT LOGIN button to log into your existing Parent
                            account.
                          </Text>
                        </div>
                        <Button
                          fluid
                          size={0.5}
                          bg="success"
                          style={{ fontSize: 22 }}
                          onClick={() =>
                            navigate(`/ssite/${schoolId}/migrate/parent`)
                          }
                        >
                          I am a parent of a student and would like to signup
                        </Button>
                      </div>
                    </Grid>
                  </Box>
                </Dimmer>
              </>
            )}
          </React.Fragment>
        )}
      />
    </CenterContainer>
  );
}

StudentLogin.propTypes = {
  match: PropTypes.object.isRequired,
};
