import React from "react";
import PropTypes from "prop-types";
import { initiateTwoFactor } from "redux/modules/twofactor";
import { getUserId, userHasPhone } from "redux/selectors/user";
import Redirect from "components/Redirect";
import { Segment, Header, Text, Form, Select, Divider } from "@jjordy/layout";
import ErrorDisplay from "components/Errors/ErrorDisplay";
import ClientLogo from "components/Elements/ClientLogo";
import CenterContainer from "components/Elements/CenterContainer";
import WithStore from "components/WithStore";
import { Formik, Field } from "formik";
import FormikField from "components/Forms/FormikField";
import SubmitButton from "components/Elements/SubmitButton";

export const Initiate = ({
  loading,
  dispatch,
  userId,
  corpId,
  userHasPhone,
}) => {
  const twoFactorProviders = [{ value: "Email", label: "Email" }];
  if (userHasPhone) {
    twoFactorProviders.push({ value: "Sms", label: "SMS/Text" });
  }
  return (
    <CenterContainer pageTitle="Two Factor Authentication">
      <Segment attached="top" bg="primary">
        <ClientLogo corp={corpId} />
        <Header
          children="TWO FACTOR AUTHENTICATION"
          fg="primary"
          as="h5"
          textAlign="center"
        />
      </Segment>
      <Segment loading={loading} attached="bottom">
        <Text mb={2}>
          Please select your <strong>Two Factor</strong> Authentication method
          below.
        </Text>
        <ErrorDisplay>
          {({ setError }) => (
            <div>
              <Formik
                initialValues={{ twoFactorMethod: "Email" }}
                onSubmit={(values) =>
                  dispatch(
                    initiateTwoFactor(userId, values.twoFactorMethod)
                  ).catch((err) => setError({ error: true, errorMessage: err }))
                }
                validate={(values) => {
                  const errors = {};
                  if (!values.twoFactorMethod) {
                    errors.twoFactorMethod = "Two Factor Method Required.";
                  }
                  return errors;
                }}
              >
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Field
                      component={FormikField}
                      name="twoFactorMethod"
                      As={Select}
                      options={twoFactorProviders}
                      label="Two Factor Method"
                    />
                    <Divider />
                    <SubmitButton />
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </ErrorDisplay>
      </Segment>
    </CenterContainer>
  );
};

Initiate.propTypes = {
  corpId: PropTypes.number,
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  userId: PropTypes.string,
  userHasPhone: PropTypes.bool,
};

export default function InitiateContainer() {
  return (
    <WithStore
      selector={(state) => ({
        loading: state.twofactor.loading,
        userId: getUserId(state),
        corpId: state.school.corpId,
        userHasPhone: userHasPhone(state),
      })}
      render={({ loading, userId, corpId, userHasPhone }, dispatch) => {
        if (userId) {
          return (
            <Initiate
              loading={loading}
              userId={userId}
              dispatch={dispatch}
              corpId={corpId}
              userHasPhone={userHasPhone}
            />
          );
        } else if (!loading) {
          return <Redirect to="/" />;
        } else {
          return null;
        }
      }}
    />
  );
}
