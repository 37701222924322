import React from "react";
import PropTypes from "prop-types";
import { verifyTwoFactor } from "redux/modules/twofactor";
import { post2FALogin } from "redux/modules/login";
import { getUserId, loading } from "redux/selectors/user";
import Redirect from "components/Redirect";
import {
  Segment,
  Header,
  Text,
  Flex,
  Button,
  Divider,
  Message,
} from "@jjordy/layout";
import ErrorDisplay from "components/Errors/ErrorDisplay";
import EnterCodeForm from "./EnterCodeForm";
import CenterContainer from "components/Elements/CenterContainer";
import WithStore from "components/WithStore";
import ClientLogo from "components/Elements/ClientLogo";
import { goBack } from "connected-react-router";

export const EnterCode = ({ dispatch, userId, loading, corpId, data }) => (
  <CenterContainer pageTitle="Two Factor Authentication">
    <Segment attached="top" bg="primary">
      <ClientLogo corp={corpId} />
      <Header
        children="TWO FACTOR AUTHENTICATION"
        fg="primary"
        as="h4"
        textAlign="center"
      />
    </Segment>
    <Segment attached="bottom">
      <Flex justify="space-between" align="center">
        <Text fg="grey" strong>
          Please enter the code you received
        </Text>
      </Flex>
      <Text small fg="grey" m={0}>
        <i>The code was sent to {data && data.receiver && data.receiver}</i>
      </Text>

      <Text fg="grey" mb={1} />
      <ErrorDisplay>
        {({ setError }) => (
          <EnterCodeForm
            submit={(values) =>
              dispatch(verifyTwoFactor({ userId, ...values }))
                .then(() => dispatch(post2FALogin()))
                .catch((e) =>
                  setError({
                    error: true,
                    errorMessage: "Two Factor Code is incorrect.",
                  })
                )
            }
            loading={loading}
          />
        )}
      </ErrorDisplay>
      <Divider />
      <Button
        fluid
        icon="warning"
        bg="error"
        onClick={() => dispatch(goBack())}
        labelPosition="right"
      >
        I didn't receive my code
      </Button>
    </Segment>
  </CenterContainer>
);

EnterCode.propTypes = {
  corpId: PropTypes.number,
  dispatch: PropTypes.func,
  userId: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
};

export default function EnterCodeContainer() {
  return (
    <WithStore
      selector={(state) => ({
        loading: loading(state),
        userId: getUserId(state),
        data: state.twofactor.data,
        corpId: state.school.corpId,
      })}
      render={({ loading, userId, working, corpId, data }, dispatch) => {
        if (userId) {
          return (
            <EnterCode
              dispatch={dispatch}
              userId={userId}
              loading={loading}
              data={data}
              corpId={corpId}
            />
          );
        } else if (!loading) {
          return <Redirect to="/" />;
        } else {
          return null;
        }
      }}
    />
  );
}
