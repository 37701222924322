import { handleActions, createAction } from "redux-actions";
import http from "../http";
import { goBack, push } from "connected-react-router";
import { createMessage } from "./message";

const migrateAcctReq = createAction("MIGRATE_ACCOUNT_REQ");
const migrateAcctSuc = createAction("MIGRATE_ACCOUNT_SUC");
const migrateAcctFail = createAction("MIGRATE_ACCOUNT_FAIL");

const migrateParentAcctReq = createAction("MIGRATE_PARENT_ACCOUNT_REQ");
const migrateParentAcctSuc = createAction("MIGRATE_PARENT_ACCOUNT_SUC");
const migrateParentAcctFail = createAction("MIGRATE_PARENT_ACCOUNT_FAIL");

const migrateAdminAcctReq = createAction("MIGRATE_ADMIN_ACCOUNT_REQ");
const migrateAdminAcctSuc = createAction("MIGRATE_ADMIN_ACCOUNT_SUC");
const migrateAdminAcctFail = createAction("MIGRATE_ADMIN_ACCOUNT_FAIL");

const api = http();

export const migrateAccount = (values) => {
  return async (dispatch, getState) => {
    dispatch(migrateAcctReq());
    const migrationInfo = getState().login.migration;
    try {
      const { data } = await api.post("/legacy/ssite/student/migrate-account", {
        ...migrationInfo,
        ...values,
      });
      dispatch(migrateAcctSuc(data));
      if (data.warning) {
        dispatch(
          createMessage({
            content: data.warning,
            color: "orange",
            header: "WARNING",
          })
        );
      } else {
        dispatch(
          createMessage({
            content: data.message,
            color: "primary",
            header: "SUCCESS",
          })
        );
      }
      dispatch(goBack());
    } catch ({ response }) {
      dispatch(migrateAcctFail(response.data));
      throw new Error(response.data.error);
    }
  };
};

export const migrateParentAccount = (values) => {
  return async (dispatch) => {
    dispatch(migrateParentAcctReq());
    try {
      const { data } = await api.post(
        "/legacy/ssite/parent/migrate-account",
        values
      );
      dispatch(migrateParentAcctSuc(data));
      if (data.warning) {
        dispatch(
          createMessage({
            content: data.warning,
            color: "orange",
            header: "WARNING",
          })
        );
      } else {
        dispatch(
          createMessage({
            content: data.message,
            color: "primary",
            header: "SUCCESS",
          })
        );
      }
      dispatch(push(`/ssite/${values.schoolId}/parent-login`));
    } catch ({ response }) {
      dispatch(migrateParentAcctFail(response.data));
      throw new Error(response.data.error);
    }
  };
};

export const migrateAdminAccount = (values) => {
  return async (dispatch, getState) => {
    const migrationInfo = getState().login.migration;
    dispatch(migrateParentAcctReq());
    try {
      const { data } = await api.post("/legacy/ssite/admin/migrate-account", {
        ...migrationInfo,
        ...values,
      });
      dispatch(migrateParentAcctSuc(data));
      if (data.warning) {
        dispatch(
          createMessage({
            content: data.warning,
            color: "orange",
            header: "WARNING",
          })
        );
      } else {
        dispatch(
          createMessage({
            content: data.message,
            color: "primary",
            header: "SUCCESS",
          })
        );
      }
      dispatch(goBack());
    } catch ({ response }) {
      dispatch(migrateParentAcctFail(response.data));
      throw new Error(response.data.error);
    }
  };
};

const initialState = {
  data: null,
  error: null,
  loading: false,
};

export default handleActions(
  {
    [migrateAcctReq]: (state, { payload }) => ({
      ...state,
      loading: true,
      data: payload,
    }),
    [migrateAcctSuc]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
    [migrateAcctFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
    [migrateParentAcctReq]: (state, { payload }) => ({
      ...state,
      loading: true,
      data: payload,
    }),
    [migrateParentAcctSuc]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
    [migrateParentAcctFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
    [migrateAdminAcctReq]: (state, { payload }) => ({
      ...state,
      loading: true,
      data: payload,
    }),
    [migrateAdminAcctSuc]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
    [migrateAdminAcctFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
  },
  initialState
);
