import React from "react";
import PropTypes from "prop-types";

export default class Component extends React.PureComponent {
  componentDidMount() {
    this.props.componentDidMount();
  }

  componentDidUpdate() {
    this.props.componentDidUpdate();
  }

  render() {
    if (this.props.render) {
      return <div>{this.props.render({})}</div>;
    } else if (this.props.children) {
      return <div>{this.props.children({})}</div>;
    }
    return null;
  }
}

Component.propTypes = {
  render: PropTypes.func,
  componentDidMount: PropTypes.func,
  componentDidUpdate: PropTypes.func,
  children: PropTypes.func,
};

Component.defaultProps = {
  componentDidMount: () => {},
  componentDidUpdate: () => {},
};
