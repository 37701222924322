import React from "react";
import { Form, Button, Divider } from "@jjordy/layout";
import SSNInput from "components/Forms/SSNInput";
import FormikField from "components/Forms/FormikField";
import MaskedInput from "components/Forms/MaskedInput";
import { Formik, Field } from "formik";
import { format } from "date-fns";
import Link from "components/QueryStateLink";

export function ConfirmStudentInfoForm({ selectedStudent, onSubmit, loading }) {
  return (
    <Formik
      onSubmit={() => onSubmit()}
      validate={(values) => {
        const errors = {};
        const cleanedSSN = values.ssn.replace(/-/g, "");
        if (cleanedSSN.length !== 9) {
          errors.ssn = "Please enter a valid Social security number";
        }
        if (cleanedSSN.length === 9 && cleanedSSN !== selectedStudent.ssn) {
          errors.ssn = "Social Security Number is incorrect for this student.";
        }
        if (!values.dob.length) {
          errors.dob = "Date of birth is required.";
        }
        if (values.dob.length) {
          const enteredDate = format(new Date(values.dob), "MM/dd/yyyy");
          const birthday = format(
            new Date(selectedStudent.dob.split("T")[0].replaceAll("-", "/")),
            "MM/dd/yyyy"
          );
          if (enteredDate !== birthday) {
            errors.dob = "Please enter the correct birthday for this student.";
          }
        }
        if (!values.pin.length) {
          errors.pin = "Pin is required.";
        }

        if (values.pin.length) {
          if (values.pin !== selectedStudent.pin) {
            errors.pin = "Please enter the correct pin for this student.";
          }
        }
        return errors;
      }}
      initialValues={{ ssn: "", dob: "", pin: "" }}
    >
      {({ handleSubmit, setFieldValue, errors, setFieldTouched }) => (
        <Form onSubmit={handleSubmit}>
          <Field
            component={FormikField}
            As={SSNInput}
            name="ssn"
            label={"Student's Social Security Number"}
            required
            onChange={(v) => {
              setFieldValue("ssn", v);
              setFieldTouched("ssn");
            }}
          />
          <Field
            component={FormikField}
            As={MaskedInput}
            mask="99/99/9999"
            type="text"
            required
            name="dob"
            label={"Student's Date Of Birth"}
            placeholder="**/**/****"
          />
          <Field
            required
            component={FormikField}
            type="text"
            name="pin"
            label="PIN"
          />
          <Divider />
          <Button
            fluid
            type="submit"
            bg="primary"
            disabled={loading || Object.keys(errors).length}
          >
            VERIFY STUDENT INFORMATION
          </Button>
          <Divider />
          <Button fluid as={Link} ignoreQS to="/parent-forgot-pin">
            FORGOT PIN
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default ConfirmStudentInfoForm;
