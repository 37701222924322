import { handleActions, createAction } from "redux-actions";

export const setQuery = createAction("SET_QUERY_PARAMS");

const initialState = {};

export default handleActions(
  {
    [setQuery]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState
);
