import React from "react";
import PropTypes from "prop-types";
import { Select, Text, Checkbox, Segment, Label } from "@jjordy/layout";
import SignupForm from "components/Forms/SignupForm";
import FormikField from "components/Forms/FormikField";
import { FastField as Field, Field as SlowField } from "formik";
import validate from "./validations";
import styled from "styled-components";

const CustomLabel = styled.label`
  color: ${(props) => props.theme.colors.grey};
  font-size: 0.8rem;
  font-weight: bold;
`;

const CustomSelect = styled.select`
  border-radius: 3px;
  height: 31px;
  color: #777;
  border: 1px solid #e7e7e7;
  width: 100%;
`;

const cleanMask = (v) => v.replace(/\(|\)|\/|-|\s/g, "");

const LegacySignup = ({
  programs,
  startdates,
  countries,
  states,
  bestTimeToCall,
  loadStartDates,
  initialValues,
  schoolLoading,
  onSubmit,
}) => {
  if (programs && startdates && countries && states && bestTimeToCall) {
    return (
      <SignupForm
        submit={(values) => {
          const cleanedPhone = cleanMask(values.phone);
          const cleanedSSN = cleanMask(values.ssn);
          const payload = Object.assign({}, values, {
            phone: cleanedPhone,
            ssn: cleanedSSN,
          });
          return onSubmit(payload);
        }}
        validations={(values) => {
          const errors = validate(values);
          return errors;
        }}
        countries={countries}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          username: "",
          password: "",
          confirmPassword: "",
          address1: "",
          dob: "",
          address2: "",
          ssn: "",
          phone: "",
          city: "",
          state: states[0].value,
          zip: "",
          SMSEnroll: false,
          country: countries[0].value,
          program: programs[0].value,
          bestTimeToCall: bestTimeToCall[0].value,
          startDate: startdates.length > 0 ? startdates[0].value : null,
        }}
        states={states}
        extraFields={({ handleChange, setFieldValue, values }) => (
          <React.Fragment>
            <Text strong fg="primary">
              School & Program
            </Text>
            <Field
              component={FormikField}
              As={Select}
              name="bestTimeToCall"
              required
              label="Best Time To Call"
              options={bestTimeToCall || []}
            />
            <Field
              component={FormikField}
              As={Select}
              name="program"
              required
              label="Program"
              options={programs || []}
              onChange={(evt) => {
                loadStartDates(evt.target.value).then((res) => {
                  if (res && res[0]) {
                    setFieldValue("startDate", res[0].value);
                  }
                });
                handleChange(evt);
              }}
            />
            {/* Use Normal field to make sure formik updates */}
            <SlowField
              component={FormikField}
              As={Select}
              name="startDate"
              required
              label="Start Date"
              options={startdates || []}
            />
            <Segment bg="primary" my={2}>
              <Field
                component={FormikField}
                As={Checkbox}
                bg="primary"
                name="SMSEnroll"
                label="Would you like to enroll in SMS/Text Notifications?"
                onChange={(v) => setFieldValue("SMSEnroll", v)}
              />
            </Segment>
            <Text style={{ textAlign: "center" }}>
              By clicking the SIGNUP button below you are selecting to
              participate in the online financial aid process.
              <br /> In addition to being an electronic process this website
              also uses cookies.
            </Text>
          </React.Fragment>
        )}
      />
    );
  }
  return null;
};

LegacySignup.propTypes = {
  programs: PropTypes.array,
  startdates: PropTypes.array,
  countries: PropTypes.array,
  states: PropTypes.array,
  bestTimeToCall: PropTypes.array,
  loadStartDates: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default LegacySignup;
