import React from "react";
import { Header } from "@jjordy/layout";

export default function InviteCancelled() {
  return (
    <Header as="h3" color="red">
      Your invitation link has already been used. Please contact your
      administrator.
    </Header>
  );
}
