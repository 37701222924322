import { handleActions, createAction } from "redux-actions";
import { createMessage } from "./message";
import api from "../http";

export const getProfileReq = createAction("GET_PROFILE_REQ");
export const getProfileSuc = createAction("GET_PROFILE_SUC");
export const getProfileFail = createAction("GET_PROFILE_FAIL");

export const updateProfileReq = createAction("UPDATE_PROFILE_REQ");
export const updateProfileSuc = createAction("UPDATE_PROFILE_SUC");
export const updateProfileFail = createAction("UPDATE_PROFILE_FAIL");

export const updatePasswordReq = createAction("UPDATE_PASSWORD_REQ");
export const updatePasswordSuc = createAction("UPDATE_PASSWORD_SUC");
export const updatePasswordFail = createAction("UPDATE_PASSWORD_FAIL");

export const redirectDepReq = createAction("REDIRECT_DEPENDENT_REQ");
export const redirectDepSuc = createAction("REDIRECT_DEPENDENT_SUC");
export const redirectDepFail = createAction("REDIRECT_DEPENDENT_FAIL");

export const setAuthorized = createAction("SET_AUTHORIZED");

export const getProfile = (token) => {
  return async (dispatch) => {
    try {
      dispatch(getProfileReq());
      const { data } = await api().get("/my-profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(getProfileSuc(data));
      dispatch(setAuthorized(true));
    } catch ({ response }) {
      dispatch(getProfileFail(response.data));
      if (response.status === 401) {
        dispatch(setAuthorized(false));
      }
    }
  };
};

export const redirectDependent = (token, studentId) => {
  return async (dispatch) => {
    dispatch(redirectDepReq());
    try {
      const { data } = await api().get(
        `/my-profile/redirect-dependent?studentId=${studentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(redirectDepSuc(data));
      if (data.redirectURL) {
        window.location = data.redirectURL;
      } else {
        dispatch(
          createMessage({
            content:
              "Unable to login to dependent account please contact support.",
            color: "red",
            header: "ERROR",
          })
        );
      }
    } catch (err) {
      dispatch(redirectDepFail(err));
    }
  };
};

export const updateProfile = (values, token) => {
  return async (dispatch) => {
    try {
      dispatch(updateProfileReq());
      const { data } = await api().post("/my-profile", values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(updateProfileSuc(data));
      dispatch(
        createMessage({
          header: "SUCCESS",
          children: "Profile Updated Successfully",
          color: "primary",
        })
      );
    } catch (err) {
      dispatch(updateProfileFail(err.response.data));
      dispatch(
        createMessage({
          header: "ERROR",
          children: "Unable to update profile information.",
          color: "error",
        })
      );
    }
  };
};

export const updatePassword = (values, token) => {
  return async (dispatch) => {
    try {
      dispatch(updatePasswordReq());
      await api().post("/my-profile/update-password", values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(updatePasswordSuc());
      dispatch(
        createMessage({
          header: "SUCCESS",
          children: "Password Updated Successfully",
          color: "primary",
        })
      );
    } catch (err) {
      dispatch(updatePasswordFail(err.response.data));
      dispatch(
        createMessage({
          header: "ERROR",
          children: "Unable to update password",
          color: "error",
        })
      );
    }
  };
};

const initialState = {
  loading: false,
  data: null,
  error: null,
  authorized: null,
  multiAccountSelection: null,
};

export default handleActions(
  {
    [getProfileReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [getProfileSuc]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
    [getProfileFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [updateProfileReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [updateProfileSuc]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
    [updateProfileFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [redirectDepReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [redirectDepSuc]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
    [redirectDepFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [setAuthorized]: (state, { payload }) => ({
      ...state,
      authorized: payload,
    }),
  },
  initialState
);
