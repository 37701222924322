import React from "react";
import CenterContainer from "components/Elements/CenterContainer";
import { Segment, Header, Dimmer, Loader, Label } from "@jjordy/layout";
import ClientLogo from "components/Elements/ClientLogo";
import WithStore from "components/WithStore";
import Component from "components/Component";
import { multiAccountSelectLogin } from "redux/modules/login";
import Redirect from "components/Redirect";
import ConfirmStudentInfoForm from "components/Forms/ConfirmStudentInfo";

const getState = (state) => ({
  multiAccountSelection: state.login.data.multiAccountSelection,
  loading: state.login.loading,
});

export function VerifyStudentAccount({ match }) {
  return (
    <CenterContainer pageTitle="Select an account">
      <WithStore
        selector={getState}
        render={({ multiAccountSelection, loading }, dispatch) => (
          <Component
            render={() => {
              if (Array.isArray(multiAccountSelection)) {
                const selectedStudent = multiAccountSelection.find(
                  (s) => s.studentId === parseInt(match.params.id, 10)
                );
                return (
                  <Segment bg="primary" mt={2} clearing>
                    <Dimmer active={loading}>
                      <Loader active />
                    </Dimmer>
                    <ClientLogo />
                    <Header as="h5" fg="primary" textAlign="center">
                      Verify Student Information for
                      <br />
                      <br />
                      <Label>
                        {selectedStudent.firstName} - {selectedStudent.lastName}
                      </Label>
                    </Header>
                    <ConfirmStudentInfoForm
                      onSubmit={() =>
                        dispatch(multiAccountSelectLogin(match.params.id))
                      }
                      selectedStudent={selectedStudent}
                    />
                  </Segment>
                );
              }
              return <Redirect to="/parent-login" />;
            }}
          />
        )}
      />
    </CenterContainer>
  );
}

export default VerifyStudentAccount;
