import App from "./App";
/* CHANGE PASSWORD */
import ChangePassword from "./routes/ChangePassword";
import ChangePasswordCode from "./routes/ChangePassword/Code";
import ChangePasswordError from "./routes/ChangePassword/Error";
import ChangePasswordSuccess from "./routes/ChangePassword/Success";
/* FORGOT PASSWORD */
import ForgotPassword from "./routes/ForgotPassword";
import ForgotPasswordSuccess from "./routes/ForgotPassword/Success";
/* FORGOT USERNAME */
import ForgotUsername from "./routes/ForgotUsername";
import FogotUsernameSuccess from "./routes/ForgotUsername/Success";
/* TWO FACTOR */
import Initiate from "./routes/TwoFactor/Initiate";
import EnterCode from "./routes/TwoFactor/EnterCode";
import TwoFactor from "./routes/TwoFactor";
/* SSITE MY PROFILE */
import MyProfile from "./routes/MyProfile";
/* VERIFY ACCOUNT */
import VerifyAccount from "./routes/VerifyAccount";
import LinkUsed from "./routes/VerifyAccount/LinkUsed";
/* AUTH TOKEN REDIRECT */
import Auth from "./routes/Auth";
/* LOGOUT */
import Logout from "./routes/Logout";
/* NSLDS INVITES */
import Invite from "./routes/Invites";
/* NEXTGEN */
import NextgenLogin from "./routes/Nextgen/Logins";
import LoginSuccess from "./routes/Nextgen/Logins/LoginSuccess";
import Signups from "./routes/Signups";
import NextgenMarketing from "./routes/Signups/Marketing";
/* SSITE */
import SSITE from "./routes/SSITE";
import Migration from "./routes/SSITE/Migration";
import SSITEStudentLogin from "./routes/SSITE/Logins/Student";
import SSITEParentLogin from "./routes/SSITE/Logins/Parent";
import SSITEAdminLogin from "./routes/SSITE/Logins/Admin";
import SSITEParentForgotPin from "./routes/SSITE/ForgotPin";

import MultiAccountSelect from "./routes/SSITE/MultiAccountSelect";
import VerifyStudentInfo from "./routes/SSITE/MultiAccountSelect/VerifyStudentInfo";
import VerifyStudentInfoProfile from "./routes/MyProfile/VerifyStudentInfo";

import MigrationParent from "./routes/SSITE/Migration/Parent";
import MigrationAdmin from "./routes/SSITE/Migration/Admin";
/* 404 */
import NotFound from "components/NotFound";
/* GlobalCORE */
import GCOREMain from "./routes/GCORE";
import GCOREMigrate from "./routes/GCORE/Migrate";

/*
We use a more traditional route config as opposed to the
React router paradigm here because we need to do some dataloading
on the server side and we need to know about our routes :)
*/

const routes = [
  {
    component: App,
    routes: [
      {
        path: "/",
        exact: true,
        component: NextgenLogin,
      },
      {
        path: "/logout",
        component: Logout,
      },
      {
        path: "/invite/:id",
        component: Invite,
      },
      {
        path: "/my-profile",
        component: MyProfile,
      },
      {
        path: "/auth/:token",
        component: Auth,
      },
      {
        path: "/gcore",
        component: GCOREMain,
        routes: [
          {
            path: "/gcore/migrate",
            component: GCOREMigrate,
          },
        ],
      },
      {
        path: "/ssite/:school",
        component: SSITE,
        routes: [
          {
            path: "/ssite/multi-account-select",
            exact: true,
            component: MultiAccountSelect,
          },
          {
            path: "/ssite/multi-account-select/verify/:id",
            component: VerifyStudentInfo,
          },
          {
            path: "/ssite/:school",
            exact: true,
            component: SSITEStudentLogin,
          },
          {
            path: "/ssite/:school/login/success",
            component: LoginSuccess,
          },
          {
            path: "/ssite/:school/migrate",
            exact: true,
            component: Migration,
          },
          {
            path: "/ssite/:school/migrate/admin",
            exact: true,
            component: MigrationAdmin,
          },
          {
            path: "/ssite/:school/migrate/parent",
            component: MigrationParent,
          },
          {
            path: "/ssite/:school/my-profile",
            exact: true,
            component: MyProfile,
          },
          {
            path: "/ssite/:school/my-profile/verify-dependent/:id",
            component: VerifyStudentInfoProfile,
          },
          {
            path: "/ssite/:school/signup",
            component: Signups,
          },
          {
            path: "/ssite/:school/parent-login",
            component: SSITEParentLogin,
          },
          {
            path: "/ssite/:school/parent-forgot-pin",
            component: SSITEParentForgotPin,
          },
          {
            path: "/ssite/:school/admin-login",
            component: SSITEAdminLogin,
          },
          {
            path: "/ssite/:school/forgot-password",
            exact: true,
            component: ForgotPassword,
          },
          {
            path: "/ssite/:school/forgot-password/success",
            component: ForgotPasswordSuccess,
          },
          {
            path: "/ssite/:school/forgot-username",
            exact: true,
            component: ForgotUsername,
          },
          {
            path: "/ssite/:school/forgot-username/success",
            component: FogotUsernameSuccess,
          },
          {
            path: "/ssite/:school/change-password",
            exact: true,
            component: ChangePassword,
          },
          {
            path: "/ssite/:school/change-password/code",
            component: ChangePasswordCode,
          },
          {
            path: "/ssite/:school/change-password/error",
            component: ChangePasswordError,
          },
          {
            path: "/ssite/:school/change-password/success",
            component: ChangePasswordSuccess,
          },
          {
            path: "/ssite/:school/login/success",
            component: LoginSuccess,
          },
        ],
      },
      {
        path: "/two-factor",
        component: TwoFactor,
        routes: [
          {
            path: "/two-factor",
            exact: true,
            component: Initiate,
          },
          {
            path: "/two-factor/enter-code",
            component: EnterCode,
          },
        ],
      },
      {
        path: "/login/success",
        component: LoginSuccess,
      },
      {
        path: "/forgot-password",
        exact: true,
        component: ForgotPassword,
      },
      {
        path: "/forgot-password/success",
        component: ForgotPasswordSuccess,
      },
      {
        path: "/forgot-username",
        exact: true,
        component: ForgotUsername,
      },
      {
        path: "/forgot-username/success",
        component: FogotUsernameSuccess,
      },
      {
        path: "/change-password",
        exact: true,
        component: ChangePassword,
      },
      {
        path: "/change-password/code",
        component: ChangePasswordCode,
      },
      {
        path: "/change-password/error",
        component: ChangePasswordError,
      },
      {
        path: "/change-password/success",
        component: ChangePasswordSuccess,
      },
      {
        path: "/signup",
        exact: true,
        component: NextgenMarketing,
      },
      {
        path: "/signup/:product",
        component: Signups,
      },
      {
        path: "/verify/linkused",
        component: LinkUsed,
      },
      {
        path: "/verify/:id",
        component: VerifyAccount,
      },
      {
        component: NotFound,
      },
    ],
  },
];

export default routes;
