import React from "react";
import PropTypes from "prop-types";
import { Form, Divider, Text, Checkbox, Message, Icon } from "@jjordy/layout";
import SubmitButton from "components/Elements/SubmitButton";
import { Formik, FastField as Field } from "formik";
import { createValidator, required } from "@jjordy/validation";
import FormikField from "components/Forms/FormikField";
import Center from "components/Elements/Center";

const validate = createValidator({
  token: [required],
});

export default function EnterCodeForm({ submit, loading }) {
  return (
    <Formik
      onSubmit={submit}
      initialValues={{ token: "", trustComputer: false }}
      validate={(values) => {
        const errors = validate(values);
        return errors;
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <Field
            component={FormikField}
            required
            type="text"
            label="Two Factor Code"
            name="token"
            mb={0}
          />
          <Center style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Checkbox
              label="Trust this device"
              id="id_trustComputer"
              bg="primary"
              name="trustComputer"
              checked={values.trustComputer}
              onBlur={handleBlur}
              error={touched.trustComputer && errors.trustComputer}
              message={touched.trustComputer && errors.trustComputer}
              onChange={(v) => setFieldValue("trustComputer", v)}
              mb={1}
            />
          </Center>

          <Message>
            <Message.Icon>
              <Icon icon="question" size={2} bg="red" />
            </Message.Icon>
            <Message.Content>
              <Message.Header>
                <Text strong>Attention</Text>
              </Message.Header>
              <Text small my={2}>
                By selecting trust this device you will not be required to
                complete two factor authentication for 30 days.
              </Text>
            </Message.Content>
          </Message>
          <Divider />
          <SubmitButton />
        </Form>
      )}
    </Formik>
  );
}

EnterCodeForm.propTypes = {
  submit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
