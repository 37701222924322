import React from "react";
import { Text } from "@jjordy/layout";
import Center from "components/Elements/Center";

const Legal = () => (
  <Center>
    <Text strong fg="grey">
      By signing up you agree to our
      <br />
      <small>
        <a
          href="http://www.globalfas.com/legal/datacheck/terms-of-service"
          target="_blank"
          rel="noopener"
        >
          Terms of Service
        </a>
      </small>{" "}
      <small>& </small>
      <small>
        <a
          href="http://www.globalfas.com/legal/datacheck/privacy-policy"
          target="_blank"
          rel="noopener"
        >
          Privacy Policy
        </a>
      </small>
    </Text>
    <br />
    <br />
  </Center>
);

export default Legal;
