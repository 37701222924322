import React from "react";
import InputMask from "react-input-mask";
import { Input } from "@jjordy/layout";

const MaskedInput = ({ mask, value, onChange, ...rest }) => (
  <InputMask
    mask={mask}
    maskChar=""
    value={value}
    onChange={onChange}
    {...rest}
  >
    {(inputProps) => {
      return <Input {...inputProps} />;
    }}
  </InputMask>
);

export default MaskedInput;
