import { handleActions, createAction } from "redux-actions";
import { setTheme } from "./theme";
import api from "../http";

const loadSchoolReq = createAction("LOAD_SCHOOL_REQ");
const loadSchoolSuc = createAction("LOAD_SCHOOL_SUC");
const loadSchoolFail = createAction("LOAD_SCHOOL_FAIL");

const loadProgramsReq = createAction("LOAD_PROGRAMS_REQ");
const loadProgramsSuc = createAction("LOAD_PROGRAMS_SUC");
const loadProgramsFail = createAction("LOAD_PROGRAMS_FAIL");

const loadStartDatesReq = createAction("LOAD_STARTDATES_REQ");
const loadStartDatesSuc = createAction("LOAD_STARTDATES_SUC");
const loadStartDatesFail = createAction("LOAD_STARTDATES_FAIL");

export const loadSchool = (id) => {
  return async (dispatch) => {
    dispatch(loadSchoolReq());
    try {
      const {
        data: { school, tenant, theme },
      } = await api().get(`/school/${id}`);
      dispatch(loadSchoolSuc({ ...school, ...tenant }));
      dispatch(setTheme(theme));
    } catch (err) {
      if (err.response && err.response.data) {
        dispatch(loadSchoolFail(err.response.statusText));
      }
    }
  };
};

export const loadPrograms = (id) => {
  return async (dispatch) => {
    dispatch(loadProgramsReq());
    try {
      const { data } = await api().get(`/school/${id}/programs`);
      dispatch(loadProgramsSuc(data));
      return data;
    } catch (err) {
      if (err.response && err.response.data) {
        dispatch(loadProgramsFail(err.response.statusText));
        throw new Error(err);
      }
    }
  };
};

export const loadStartDates = async (id, scheduleId) => {
  return async (dispatch) => {
    dispatch(loadStartDatesReq());
    try {
      const { data } = await api().get(
        `/school/${id}/startdates/${scheduleId}`
      );
      dispatch(loadStartDatesSuc(data));
      return data;
    } catch (err) {
      if (err.response && err.response.data) {
        dispatch(loadStartDatesFail(err.response.statusText));
        throw new Error("Error loading startdates...");
      }
    }
  };
};

const initialState = {
  error: null,
  loading: false,
  loaded: false,
  programs: null,
  startdates: null,
  branchId: null,
  opeid: null,
  name: null,
  corpId: null,
  schoolId: null,
  redirectURL: null,
};

export default handleActions(
  {
    [loadSchoolReq]: (state) => ({
      ...state,
      loaded: false,
      loading: true,
    }),
    [loadSchoolSuc]: (state, { payload }) => ({
      ...state,
      ...payload,
      loaded: true,
      loading: false,
    }),
    [loadSchoolFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      loaded: true,
      error: payload,
    }),
    [loadProgramsReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [loadProgramsSuc]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [loadProgramsFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [loadStartDatesReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [loadStartDatesSuc]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [loadStartDatesFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
  initialState
);
