import { handleActions, createAction } from "redux-actions";
import http from "../http";

export const logout = createAction("AUTH_LOGOUT");
export const logoutReq = createAction("AUTH_LOGOUT_REQ");
export const logoutSuc = createAction("AUTH_LOGOUT_SUC");
export const logoutFail = createAction("AUTH_LOGOUT_FAIL");

const api = http();

export const logoutAsync = () => {
  return async (dispatch) => {
    dispatch(logoutReq());
    try {
      await api.post("/logout");
      dispatch(logoutSuc());
      dispatch(logout());
    } catch (err) {
      dispatch(logoutFail());
    }
  };
};

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export default handleActions(
  {
    [logout]: (state) => ({
      ...state,
      loading: false,
    }),
    [logoutReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [logoutSuc]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
    [logoutFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
  initialState
);
