import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import WithStore from "./WithStore";
import qs from "qs";
import { createSelector } from "reselect";

const schoolSelector = (state) => state.school;

const getSchoolIdSelector = createSelector(schoolSelector, (data) => {
  if (data && data.schoolId) {
    return data.schoolId;
  }
  return null;
});

const QueryLink = ({
  to,
  schoolId,
  query,
  className,
  ignoreQS,
  children,
  style,
}) => {
  let path = "";
  if (schoolId) {
    path += `/ssite/${schoolId}${to}`;
  } else {
    path += to;
  }
  const queryString = qs.stringify(query);
  return (
    <Link
      className={className}
      to={{ pathname: path, search: !ignoreQS ? queryString : null }}
      children={children}
      style={Object.assign({}, style, { textDecoration: "none" })}
    />
  );
};

export function QueryStateLink({ theme, ignoreQS, to, ...rest }) {
  const getState = (state) => ({
    query: state.query,
    schoolId: getSchoolIdSelector(state),
  });
  return (
    <WithStore
      selector={getState}
      render={({ query, schoolId }) => (
        <QueryLink
          to={to}
          theme={theme}
          ignoreQS={ignoreQS}
          {...rest}
          query={query}
          schoolId={schoolId}
        />
      )}
    />
  );
}

QueryLink.propTypes = {
  to: PropTypes.string.isRequired,
  ignoreQS: PropTypes.bool,
  schoolId: PropTypes.string,
  query: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default QueryStateLink;
