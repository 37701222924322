import React from "react";
import MaskedInput from "./MaskedInput";
import { Button, Icon } from "@jjordy/layout";

export default class SSNInput extends React.Component {
  state = { visible: false, value: "", mask: "" };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  handleChange = (evt) => {
    this.setState({ value: evt.target.value });
    this.props.onChange(evt.target.value);
  };

  render() {
    const { visible, value } = this.state;
    const { onChange, ...rest } = this.props;
    return (
      <MaskedInput
        type={visible ? "text" : "password"}
        mask="999-99-9999"
        label="Social Security Number"
        placeholder="***-**-****"
        onChange={this.handleChange}
        value={value}
        action={() => (
          <Button
            id="id_ssn_show_hide_action"
            onClick={this.toggleVisibility}
            type="button"
            bg="primary"
            labelPosition="right"
            title={visible ? "Hide" : "Show"}
          >
            <Icon icon={visible ? "eye" : "eye_slash"} />
          </Button>
        )}
        {...rest}
      />
    );
  }
}
