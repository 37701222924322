import { handleActions, createAction } from "redux-actions";
import api from "../http";

export const verifyUserReq = createAction("VERIFY_USER_REQUEST");
export const verifyUserSuccess = createAction("VERIFY_USER_SUCCESS");
export const verifyUserFailure = createAction("VERIFY_USER_FAILURE");
export const linkUsed = createAction("SET_LINK_USED");

export const verify = (id, code) => {
  return (dispatch) => {
    dispatch(verifyUserReq());
    return api()
      .get(`/nextgen/confirmEmail?userId=${id}&code=${code}`)
      .then((res) => {
        dispatch(verifyUserSuccess());
      })
      .catch((err) => {
        if (err.response.status === 400) {
          dispatch(linkUsed());
        }
        dispatch(verifyUserFailure(err.response.data));
      });
  };
};

const initialState = {
  loading: false,
  loaded: false,
  verifySuccess: false,
  linkUsed: false,
  errors: null,
};

export default handleActions(
  {
    [verifyUserReq]: (state) => ({
      ...state,
      loaded: false,
      loading: true,
    }),
    [verifyUserSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      loaded: true,
      verifySuccess: true,
    }),
    [verifyUserFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      loaded: true,
      verifySuccess: false,
      errors: payload,
    }),
    [linkUsed]: (state) => ({
      ...state,
      linkUsed: true,
    }),
  },
  initialState
);
