import React from "react";
import Component from "components/Component";
import WithStore from "components/WithStore";
import { logoutAsync } from "redux/modules/logout";
import { push } from "connected-react-router";

const getState = (state) => ({});

export default function Logout() {
  return (
    <WithStore
      selector={getState}
      render={(props, dispatch) => (
        <Component
          componentDidMount={() =>
            dispatch(logoutAsync()).then(() => dispatch(push("/")))
          }
        />
      )}
    />
  );
}
