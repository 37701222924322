import { handleActions, createAction } from "redux-actions";
import { push } from "connected-react-router";
import http from "../http";

const forgotUsernameReq = createAction("FORGOT_USERNAME_REQ");
const forgotUsernameSuc = createAction("FORGOT_USERNAME_SUC");
const forgotUsernameFail = createAction("FORGOT_USERNAME_FAIL");

const api = http();

export const forgotUsername = ({ values, schoolId }) => {
  return async (dispatch) => {
    dispatch(forgotUsernameReq());
    try {
      const { data } = await api.post(
        `/forgot-username${schoolId ? "?type=SAINTstudent" : ""}`,
        values
      );
      dispatch(forgotUsernameSuc(data));
      dispatch(push("/forgot-username/success"));
    } catch (err) {
      dispatch(forgotUsernameFail(err));
      if (err.response && err.response.status === 400) {
        throw new Error("Email address not found");
      }
    }
  };
};

const initialState = {
  data: null,
  error: null,
  loading: false,
};

export default handleActions(
  {
    [forgotUsernameReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [forgotUsernameSuc]: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false,
    }),
    [forgotUsernameFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
  initialState
);
