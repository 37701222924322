import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "@jjordy/layout";

const SubmitButton = ({ title = "SUBMIT", ...rest }) => (
  <Button type="submit" fluid bg="primary" rounded {...rest}>
    <Button.Content>{title}</Button.Content>
    <Button.Icon>
      <Icon icon="chevron_right" bg="white" />
    </Button.Icon>
  </Button>
);

SubmitButton.propTypes = {
  title: PropTypes.string,
};

SubmitButton.displayName = "SubmitButton";

export default SubmitButton;
