import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Text, Grid, Checkbox, Segment } from "@jjordy/layout";
import { Formik, FastField as Field } from "formik";
import MaskedInput from "components/Forms/MaskedInput";
import PasswordInput from "components/Forms/PasswordInput";
import {
  createValidator,
  required,
  oneNum,
  oneCap,
  oneSpecialChar,
  minLength,
  maxLength,
  match,
  phone,
  email,
} from "@jjordy/validation";
import FormikField from "components/Forms/FormikField";

function oneLowerCase(value) {
  if (!/[a-z]/.test(value)) {
    return "Must have one lowercase letter.";
  }
}

const validate = createValidator({
  username: [required],
  email: [required, email],
  password: [
    required,
    minLength(8),
    oneNum,
    oneLowerCase,
    oneSpecialChar,
    oneCap,
  ],
  confirmPassword: [required, oneLowerCase, match("password", "Passwords")],
  phone: [required, phone],
  pin: [required, minLength(4), maxLength(4)],
});

export default function ParentMigrationForm({ submit, loading }) {
  return (
    <Formik
      onSubmit={(values) => {
        let payload = values;
        if (values.phone) {
          const cleanedPhone = values.phone.replace(/\(|\)| /g, "");
          payload = Object.assign({}, values, {
            phone: cleanedPhone,
          });
        }
        submit(payload);
      }}
      initialValues={{
        password: "",
        confirmPassword: "",
        phone: "",
        email: "",
        pin: "",
        SMSEnroll: false,
      }}
      validate={(values) => {
        const errors = validate(values);
        return errors;
      }}
    >
      {({
        values,
        handleSubmit,
        handleBlur,
        touched,
        errors,
        handleChange,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <Grid width="33%">
            <Field
              component={FormikField}
              label="Username"
              type="text"
              name="username"
              required
              message="You will use this to login."
            />
            <Field
              component={FormikField}
              label="Email"
              type="email"
              name="email"
              required
              message="Enter the email address where you received your notification."
              placeholder="email@email.com"
            />
          </Grid>
          <Field
            component={FormikField}
            label="Pin"
            type="text"
            name="pin"
            required
            message="Please enter the pin you received in your notification email.  If you have forgotten your pin, you can click the Forgot Pin link at the bottom of this page."
            placeholder="****"
          />
          <Grid width="33%">
            <Field
              component={FormikField}
              As={PasswordInput}
              label="Password"
              type="password"
              name="password"
              required
              message="Desired Password"
              placeholder="********"
            />
            <Field
              component={FormikField}
              As={PasswordInput}
              label="Confirm Password"
              type="password"
              name="confirmPassword"
              placeholder="********"
              message="Confirm Password"
              required
            />
            <Field
              component={FormikField}
              As={MaskedInput}
              label="Phone Number"
              name="phone"
              mask="(999) 999 9999"
              placeholder="Enter your phone number"
            />
          </Grid>
          <Segment bg="primary" my={2}>
            <Field
              component={FormikField}
              As={Checkbox}
              bg="primary"
              name="SMSEnroll"
              label="Would you like to enroll in SMS/Text Notifications?"
              onChange={(v) => setFieldValue("SMSEnroll", v)}
            />
          </Segment>
          <Text style={{ textAlign: "center" }}>
            By clicking the Update Account button below you are selecting to
            participate in the online financial aid process.
            <br /> In addition to being an electronic process this website also
            uses cookies.
          </Text>
          <Button
            type="submit"
            fluid
            content="Update Account"
            bg="primary"
            mt={1}
            rounded
          />
        </Form>
      )}
    </Formik>
  );
}

ParentMigrationForm.propTypes = {
  submit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
