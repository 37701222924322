import React from "react";
import PropTypes from "prop-types";
import { Flex, Icon, Text, Button, Segment } from "@jjordy/layout";
import Spacer from "components/Elements/Spacer";
import { getAttachState } from "utils";

const AccountSelectionItem = ({ account, setAccount, id, total }) => (
  <Segment attached={getAttachState(id, total)}>
    <Flex align="center">
      <Icon icon="user" size={2} bg="primary" mr={2} />
      <Text small strong fg="grey">
        {account.lastName}, {account.firstName}
      </Text>
      <Spacer />
      <Button bg="primary" onClick={() => setAccount(account.studentId)}>
        Verify & Login
      </Button>
    </Flex>
  </Segment>
);

AccountSelectionItem.propTypes = {
  account: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  setAccount: PropTypes.func.isRequired,
};

export default AccountSelectionItem;
