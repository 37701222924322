import React from "react";
import { Segment, Header } from "@jjordy/layout";
import { forgotUsername } from "redux/modules/forgotUsername";
import WithStore from "components/WithStore";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import ForgotUsernameForm from "./ForgotUsernameForm";
import ErrorDisplay from "components/Errors/ErrorDisplay";
import { getSchoolId } from "redux/selectors";

const getState = (state) => ({
  schoolId: getSchoolId(state),
  loading: state.forgotUsername.loading,
});

export default function ForgotUsername() {
  return (
    <CenterContainer pageTitle="Forgot Username">
      <WithStore
        selector={getState}
        render={({ loading, schoolId }, dispatch) => (
          <React.Fragment>
            <Segment attached="top" bg="primary">
              <ClientLogo />
              <Header fg="primary" as="h4" textAlign="center">
                FORGOT USERNAME
              </Header>
            </Segment>
            <Segment attached="bottom">
              <ErrorDisplay>
                {({ setError }) => (
                  <ForgotUsernameForm
                    loading={loading}
                    submit={(v) =>
                      dispatch(
                        forgotUsername({ values: v, schoolId: schoolId })
                      ).catch((err) => setError({ errorMessage: err.message }))
                    }
                    schoolId={schoolId}
                  />
                )}
              </ErrorDisplay>
            </Segment>
          </React.Fragment>
        )}
      />
    </CenterContainer>
  );
}
