import { handleActions, createAction } from "redux-actions";
import api from "../http";
import { createMessage } from "./message";
import { goBack, push } from "connected-react-router";

export const signupReq = createAction("SIGN_UP_REQ");
export const signupSuc = createAction("SIGN_UP_SUC");
export const signupFail = createAction("SIGN_UP_FAIL");

export const signup = (url, values) => {
  return async (dispatch) => {
    dispatch(signupReq());
    try {
      const { data } = await api().post(url, values);
      dispatch(signupSuc(data));
      if (data.warning) {
        dispatch(
          createMessage({
            content: data.warning,
            color: "orange",
            header: "WARNING",
          })
        );
      } else {
        dispatch(
          createMessage({
            content: data.message,
            color: "primary",
            header: "SUCCESS",
          })
        );
      }
      if (data.redirectTo) {
        dispatch(push(data.redirectTo));
      } else {
        dispatch(goBack());
      }
    } catch (err) {
      dispatch(signupFail(err));
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        throw new Error(err.response.data.message);
      } else if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.errors
      ) {
        if (Array.isArray(err.response.data.errors)) {
          const errString = err.response.data.errors.join("\n");
          throw new Error(errString);
        } else if (err.response.data.errors.errors) {
          const errString = err.response.data.errors.errors.join("\n");
          throw new Error(errString);
        }
      } else if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.error
      ) {
        throw new Error(err.response.data.error);
      } else if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.Errors
      ) {
        const errString = err.response.data.Errors.join("\n");
        throw new Error(errString);
      } else {
        throw new Error("Something went wrong Please try again.");
      }
    }
  };
};

const initialState = {
  loading: false,
  error: null,
  data: null,
};

export default handleActions(
  {
    [signupReq]: (state, { payload }) => ({
      ...state,
      loading: true,
    }),
    [signupSuc]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
    [signupFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
  initialState
);
