export function hexToRGBA(hex) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255];
  }
  throw new Error("Bad Hex");
}

export function brightness(r, g, b) {
  return (r * 299 + g * 587 + b * 114) / 1000;
}

export function isNode() {
  let isNode = false;
  if (
    typeof module !== "undefined" &&
    module.exports &&
    typeof window === "undefined"
  ) {
    isNode = true;
  }
  return isNode;
}

export const getAttachState = (id, total) => {
  if (id === 0) {
    return "top";
  } else if (id === total - 1) {
    return "bottom";
  } else if (id < total) {
    return true;
  } else {
    return false;
  }
};
