import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { removeMessage } from "redux/modules/message";
import { push } from "connected-react-router";
import { renderRoutes } from "react-router-config";
import WithStore from "components/WithStore";
import Message from "components/Elements/Message";
import ErrorBoundary from "components/Errors/ErrorBoundary";
import WithQueryParams from "components/WithQueryParams";
import { defaultTheme } from "@jjordy/layout";
import Component from "components/Component";
import Footer from "components/Footer";
import { ThemeProvider } from "styled-components";
import { anyLoading } from "redux/selectors";
import { GlobalStyle } from "./components/GlobalStyles";
import apiBuilder from "redux/http";
import { hot } from "react-hot-loader/root";

const api = apiBuilder();

const getState = (state) => ({
  message: state.message,
  corpId: state.school.corpId,
  theme: state.theme,
  query: state.query,
  anyLoading: anyLoading(state),
});

const App = ({ route, location }) => (
  <WithQueryParams>
    <GlobalStyle />
    <WithStore
      selector={getState}
      render={({ anyLoading, message, corpId, theme, query }, dispatch) => (
        <Component
          componentDidMount={() =>
            convertUrlIfTenant({ query, dispatch, location })
          }
          render={() => (
            <React.Fragment>
              <ThemeProvider
                // merge the default theme and custom theme if one exists.
                theme={Object.assign({}, defaultTheme, {
                  logo: theme.logo,
                  rounded: true,
                  corpId: corpId,
                  colors: {
                    ...defaultTheme.colors,
                    error: "#dd362b",
                    red: "#dd362b",
                    ...theme,
                  },
                })}
              >
                <ErrorBoundary>
                  <React.Fragment>
                    <Message
                      onClose={() => dispatch(removeMessage())}
                      {...message}
                    />
                    <div>{renderRoutes(route.routes)}</div>
                    {anyLoading ? null : <Footer />}
                  </React.Fragment>
                </ErrorBoundary>
              </ThemeProvider>
            </React.Fragment>
          )}
        />
      )}
    />
  </WithQueryParams>
);

const convertUrlIfTenant = ({ query, dispatch, location }) => {
  if (query && query.tenantId && !location.pathname.includes("/ssite")) {
    if (query.tenantId.substr(0, 4) === "0000") {
      const schoolId = query.tenantId.substr(4);
      api
        .get(`/nextgen/tenant/${query.tenantId}`)
        .then(({ data }) => {
          // all SSITE schools have legacy as there tenantDomain
          if (data.tenantDomain === "legacy") {
            dispatch(push(`/ssite/${schoolId}${location.pathname}`));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};

App.propTypes = {
  route: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
export default hot(withRouter(App));
