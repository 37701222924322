import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { setQuery } from "redux/modules/query";
import WithStore from "./WithStore";
import Component from "./Component";
import qs from "qs";

export function WithQueryParams({ location, children }) {
  const queryString = location.search.split("?")[1];
  const queryParams = qs.parse(queryString);
  return (
    <WithStore
      selector={(state) => ({ query: state.query })}
      render={({ query }, dispatch) => (
        <Component
          componentDidMount={() => {
            if (JSON.stringify(query) !== JSON.stringify(queryParams)) {
              dispatch(setQuery(queryParams));
            }
          }}
          render={() => <React.Fragment>{children}</React.Fragment>}
        />
      )}
    />
  );
}

WithQueryParams.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withRouter(WithQueryParams);
