import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Segment, Header, Divider, Button } from "@jjordy/layout";
import { login } from "redux/modules/login";
import WithStore from "components/WithStore";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import LegacyLoginForm from "./LegacyLoginForm";
import ErrorDisplay from "components/Errors/ErrorDisplay";
import { createValidator, required } from "@jjordy/validation";
import { getSchoolId } from "redux/selectors";
import Link from "components/QueryStateLink";

const validate = createValidator({
  username: [required],
  password: [required],
});

const getState = (state) => ({
  schoolId: getSchoolId(state),
  name: state.school.name,
  loading: state.login.loading,
});

export default function AdminLogin({ match }) {
  return (
    <CenterContainer pageTitle="ADMIN LOGIN">
      <WithStore
        selector={getState}
        awaiting="corpId"
        render={({ name, loading }, dispatch) => (
          <React.Fragment>
            <Helmet title={`${name} Login`} />
            <Segment attached="top" bg="primary">
              <ClientLogo />
              <Header
                children={`${name} | ADMIN LOGIN`}
                fg="primary"
                as="h5"
                textAlign="center"
              />
            </Segment>
            <Segment attached="bottom">
              <ErrorDisplay>
                {({ setError }) => (
                  <LegacyLoginForm
                    loading={loading}
                    school={match.params.school}
                    initialValues={{ username: "", password: "" }}
                    validations={(values, errors) => {
                      const e = validate(values);
                      return Object.assign({}, errors, e);
                    }}
                    login={(values) => {
                      dispatch(
                        login("/legacy/ssite/admin/login", values)
                      ).catch((err) => setError({ errorMessage: err.message }));
                    }}
                  />
                )}
              </ErrorDisplay>
              <Divider />
              <Button as={Link} to={"/forgot-password"}>
                FORGOT PASSWORD
              </Button>
              <Button as={Link} to="/forgot-username" mt={1}>
                FORGOT USERNAME
              </Button>
            </Segment>
          </React.Fragment>
        )}
      />
    </CenterContainer>
  );
}

AdminLogin.propTypes = {
  match: PropTypes.object.isRequired,
};
