import React from "react";
import { Helmet } from "react-helmet";
import { renderRoutes } from "react-router-config";

export default function TwoFactorHome({ route }) {
  return (
    <div>
      <Helmet title="Two Factor Authentication" />
      {renderRoutes(route.routes)}
    </div>
  );
}
