import React from "react";
import { Segment, Button, Header, Icon, Text } from "@jjordy/layout";
import CenterContainer from "./Elements/CenterContainer";
import Link from "./QueryStateLink";
import ClientLogo from "./Elements/ClientLogo";

export const NotFoundView = () => (
  <CenterContainer pageTitle="PAGE NOT FOUND">
    <Segment style={{ textAlign: "center" }} mt={1}>
      <ClientLogo />
      <Header as="h1" color="red" dividing>
        404 Page Not Found
      </Header>
      <Text mb={2} color="grey">
        <strong>Oops</strong> it looks like we cant find that page.
      </Text>
      <Button as={Link} to="/" fluid bg="red" rounded>
        <Button.Content style={{ float: "left" }}>GO HOME</Button.Content>
        <Button.Icon>
          <Icon icon="chevron_right" bg="white" />
        </Button.Icon>
      </Button>
    </Segment>
  </CenterContainer>
);
export default NotFoundView;
