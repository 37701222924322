import React from "react";
import PropTypes from "prop-types";
import { loadInvite, registerInvite } from "redux/modules/invite";
import CenterContainer from "components/Elements/CenterContainer";
import { Segment, Header, Text, Loader, Message } from "@jjordy/layout";
import ClientLogo from "components/Elements/ClientLogo";
import Center from "components/Elements/Center";
import WithStore from "components/WithStore";
import Component from "components/Component";
import { push } from "connected-react-router";
import InviteCancelled from "./InviteCancelled";
import InviteExpired from "./InviteExpired";
import InviteForm from "./InviteForm";

const getState = (state) => ({
  user: state.invite.data,
  loading: state.invite.loading,
  error: state.invite.error,
  expired: state.invite.expired,
  cancelled: state.invite.cancelled,
});

export default function Invites({ match }) {
  return (
    <CenterContainer pageTitle="User Invite">
      <Segment attached="top" bg="primary">
        <ClientLogo />
      </Segment>
      <Segment attached="bottom">
        <WithStore
          selector={getState}
          render={({ user, loading, error, expired, cancelled }, dispatch) => (
            <Component
              componentDidMount={() => {
                if (match.params.id) {
                  dispatch(loadInvite(match.params.id));
                } else {
                  dispatch(push("/"));
                }
              }}
              render={() => {
                if (expired) {
                  return <InviteExpired />;
                }

                if (cancelled) {
                  return <InviteCancelled />;
                }

                if (user) {
                  return (
                    <div>
                      <Header as="h5">
                        Welcome {user.userEmail} please register your account.
                      </Header>
                      {error && typeof error === "string" && (
                        <Message bg="red">
                          <Text fg="white">{error}</Text>
                        </Message>
                      )}
                      {error &&
                        error.errors &&
                        Array.isArray(error.errors.Errors) && (
                          <Message bg="red" rounded>
                            <ul style={{ color: "white" }}>
                              {error.errors.Errors.map((e, i) => (
                                <li key={`error_${i}`}>{e}</li>
                              ))}
                            </ul>
                          </Message>
                        )}
                      <Text fg="grey" strong>
                        {user.senderName} Invited you to join Global Financial
                        Aid Services
                      </Text>

                      <InviteForm
                        initialValues={user}
                        loading={loading}
                        submit={(v) =>
                          dispatch(registerInvite(v, match.params.id))
                        }
                      />
                    </div>
                  );
                }

                if (!user && loading) {
                  return (
                    <Center>
                      <Loader active type="three-dots" />
                    </Center>
                  );
                }

                return null;
              }}
            />
          )}
        />
      </Segment>
    </CenterContainer>
  );
}

Invites.propTypes = {
  match: PropTypes.object.isRequired,
};
