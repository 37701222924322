import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StyledFooter = styled.div`
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    color: #000;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    background-color: #222;
    margin-top: 100px;
  }
`;

const Footer = () => {
  const [version, setVersion] = useState("...");
  useEffect(() => {
    setVersion(__VERSION__);
  });

  return (
    <StyledFooter>
      <p>{version}</p>
    </StyledFooter>
  );
};

export default Footer;
