import React from "react";
import WithStore from "components/WithStore";
import { login } from "redux/modules/login";
import { Header, Segment, Divider, Icon, Button, Flex } from "@jjordy/layout";
import NextgenLoginForm from "./NextgenLoginForm";
import CenterContainer from "components/Elements/CenterContainer";
import ErrorDisplay from "components/Errors/ErrorDisplay";
import ClientLogo from "components/Elements/ClientLogo";
import styled from "styled-components";
import { Link } from "react-router-dom";

const MyLink = ({ to, className, children }) => {
  return (
    <Link to={to} className={className}>
      {children}
    </Link>
  );
};

const StyledLink = styled(MyLink)`
  text-decoration: none;
  color: inherit;
  margin: 0px;
  padding: 0px;
`;

export default function NextgenLogin() {
  return (
    <CenterContainer pageTitle="Client Login">
      <Segment attached="top" bg="blue">
        <ClientLogo />
        <Header
          children="PLEASE LOGIN"
          color="primary"
          textAlign="center"
          as="h4"
        />
      </Segment>
      <Segment attached="bottom">
        <WithStore
          selector={(state) => ({ loading: state.login.loading })}
          render={({ loading }, dispatch) => (
            <ErrorDisplay>
              {({ setError }) => (
                <NextgenLoginForm
                  loading={loading}
                  login={(val) =>
                    dispatch(login("/nextgen/login", val)).catch((err) =>
                      setError({ errorMessage: err.message })
                    )
                  }
                />
              )}
            </ErrorDisplay>
          )}
        />
        <Divider />
        {/* <StyledLink to="/signup">
          <Button fluid rounded bg="secondary">
            <Button.Content>SIGNUP</Button.Content>
            <Button.Icon>
              <Icon icon="chevron_right" bg="white" />
            </Button.Icon>
          </Button>
        </StyledLink> */}
        <StyledLink to="/forgot-password">
          <Header as="h6" mt={1} m={0} fg="grey">
            Forgot Password
          </Header>
        </StyledLink>
        <StyledLink to="/forgot-username">
          <Header as="h6" m={0} fg="grey">
            Forgot Username
          </Header>
        </StyledLink>
      </Segment>
    </CenterContainer>
  );
}
