import React from "react";
import PropTypes from "prop-types";
import { Prompt, Redirect } from "react-router";
import { Formik, FastField as Field } from "formik";
import { Form, Button, Divider, Flex, Grid, Checkbox } from "@jjordy/layout";
import FormikField from "components/Forms/FormikField";
import {
  createValidator,
  required,
  minLength,
  phone,
  email,
  match,
  maxLength,
  oneNum,
  oneSpecialChar,
  oneCap,
  integer,
} from "@jjordy/validation";
import PasswordInput from "components/Forms/PasswordInput";
import MaskedInput from "components/Forms/MaskedInput";
import axios from "axios";
import get from "lodash.get";
import throttle from "lodash.throttle";

const validate = (values) => {
  const errors = createValidator({
    phone: [phone],
    email: [required, email],
    username: [required],
    password: [
      required,
      minLength(8),
      maxLength(25),
      oneNum,
      oneSpecialChar,
      oneCap,
    ],
    confirmPassword: [required, match("password", "Passwords")],
    firstName: [required],
    lastName: [required],
    agreeTOS: [required],
    tenantId: [required, minLength(8), maxLength(8), integer],
  })(values);
  return errors;
};

const validateEmailAsync = async (value, tenantId) => {
  try {
    await axios.post(`/api/validations/email`, {
      email: value,
      tenantId: tenantId,
    });
    return null;
  } catch (err) {
    const emailErr = get(
      err,
      "response.data.message",
      "Async Email Validation encountered an error."
    );
    return emailErr;
  }
};

const t = throttle(validateEmailAsync, 1000, { leading: true });

const validateEmail = async (value, tenantId) => {
  return t(value, tenantId);
};

export default function MigrateForm({ onSubmit, loading, initialValues }) {
  if (!initialValues) {
    return <Redirect to="/gcore/migrate" />;
  }
  return (
    <Formik
      onSubmit={(values, formikActions) => {
        const newPhone = values.phone
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")
          .replace(" ", "");
        const payload = { ...values, phone: newPhone };
        onSubmit(payload, formikActions);
      }}
      initialValues={{
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        ...initialValues,
        password: "",
        confirmPassword: "",
        applicationName: "globalcore",
        agreeTOS: false,
      }}
      validate={validate}
    >
      {({ handleSubmit, setFieldValue, values, touched, submitCount }) => (
        <Form onSubmit={handleSubmit} loading={loading} autocomplete="off">
          <Prompt
            when={Object.keys(touched).length > 0 && submitCount === 0}
            message="You have unsaved data. Are you sure you want to proceed."
          />
          <Grid width="50%">
            <Field
              component={FormikField}
              name="email"
              label="Email Address"
              required
              validate={(value) => validateEmail(value, values.tenantId)}
              placeholder="user@school.com"
            />
            <Field
              component={FormikField}
              name="username"
              label="Username"
              required
              placeholder="GlobalUser123"
            />
          </Grid>
          <Grid width="50%">
            <Field
              component={FormikField}
              As={PasswordInput}
              type="password"
              name="password"
              message="8 Characters minimum with One special character One number and One caplital letter."
              required
              label="Password"
              placeholder="***********"
            />
            <Field
              component={FormikField}
              type="password"
              As={PasswordInput}
              required
              name="confirmPassword"
              label="Confirm Password"
              placeholder="***********"
            />
          </Grid>
          <Grid width="50%">
            <Field
              component={FormikField}
              type="text"
              name="firstName"
              required
              label="First Name"
              placeholder="Johan"
            />
            <Field
              component={FormikField}
              type="text"
              name="lastName"
              required
              label="Last Name"
              placeholder="Schmidt"
            />
          </Grid>
          <Field
            component={FormikField}
            As={MaskedInput}
            mask="(999) 999 9999"
            type="text"
            message="Two Factor and SMS notifications could trigger charges based on your cellular plan."
            name="phone"
            label="Phone Number"
            placeholder="(***) *** ****"
          />
          <Flex align="center" justify="center">
            <Checkbox
              mb={2}
              bg={values.agreeTOS ? "green" : "grey"}
              name="agreeTOS"
              required
              label="I Agree to the terms and conditions"
              onChange={(v) => setFieldValue("agreeTOS", v)}
            />
          </Flex>
          <Divider />
          <Flex justify="space-between">
            <Button
              fluid
              type="submit"
              disabled={loading}
              content="FINALIZE MIGRATION"
            />
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

MigrateForm.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  initialValues: PropTypes.object,
};
