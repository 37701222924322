import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    margin-right: 100%;
    width: 300%;
  }
  to {
    margin-right: 0%;
    width: 100%;
  }
`;

export const SlideInRight = styled.div`
  animation-duration: 3s;
  animation-name: ${slideIn};
  animation-iteration-count: 3;
  animation-direction: alternate;
`;

export const FadeIn = styled.div`
  animation: 250ms ${fadeIn} ease-in;
  flex: 1 1 auto;
  display: flex;
`;
