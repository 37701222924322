import React from "react";
import PropTypes from "prop-types";
import { Segment, Header, Text, Icon, Message, Button } from "@jjordy/layout";
import Link from "components/QueryStateLink";
import { verify } from "redux/modules/verify";
import Component from "components/Component";
import WithStore from "components/WithStore";
import CenterContainer from "components/Elements/CenterContainer";
import { Redirect } from "react-router-dom";
import ClientLogo from "components/Elements/ClientLogo";

const getState = (state) => ({
  loading: state.verify.loading,
  loaded: state.verify.loaded,
  err: state.verify.errors,
  query: state.query.code,
  linkUsed: state.verify.linkUsed,
  verifySuccess: state.verify.verifySuccess,
});

export default function VerifyAccount({ match }) {
  return (
    <CenterContainer pageTitle="Verify Account">
      <WithStore
        selector={getState}
        render={(
          { loading, verifySuccess, query, userId, err, linkUsed },
          dispatch
        ) => (
          <Component
            componentDidMount={() => {
              dispatch(verify(match.params.id, encodeURIComponent(query)));
            }}
            render={() => (
              <Segment bg="primary" loading={loading} mt={4}>
                {verifySuccess && (
                  <div>
                    <ClientLogo />
                    <br />
                    <Message bg="success">
                      <Message.Icon>
                        <Icon size={2} icon="question" bg="white" />
                      </Message.Icon>
                      <Message.Content>
                        <Message.Header style={{ fontWeight: 700 }}>
                          Congratulations!
                        </Message.Header>
                        You have verified your account.
                      </Message.Content>
                    </Message>
                    <Button as={Link} to="/">
                      LOGIN
                    </Button>
                  </div>
                )}
                {err && err.length && !verifySuccess && (
                  <div>
                    <Header as="h2" fg="error">
                      The link you are trying to access is no longer valid.
                    </Header>
                    {err.map((e, id) => (
                      <Text fg="red" key={`error_${id}`}>
                        {e}
                      </Text>
                    ))}
                    <Text fg="grey">
                      Account creation was unsuccessful and no information was
                      saved.
                      <br />
                      <strong>
                        Please return to the Global Signup and complete account
                        setup steps again.
                      </strong>
                    </Text>
                    <Button as={Link} to="/signup/">
                      SIGNUP
                    </Button>
                  </div>
                )}
                {linkUsed && <Redirect to="/verify/linkused" />}
              </Segment>
            )}
          />
        )}
      />
    </CenterContainer>
  );
}

VerifyAccount.propTypes = {
  match: PropTypes.object.isRequired,
};
