import React from "react";
import { Message, Text, Icon } from "@jjordy/layout";

export default function PhoneMessage() {
  return (
    <Message rounded>
      <Message.Icon>
        <Icon icon="question" bg="red" size={3} />
      </Message.Icon>
      <Message.Content>
        <Text small fg="grey" mb={0}>
          <strong>
            SMS/Text fees may apply in accordance with your cell provider.
          </strong>
        </Text>
      </Message.Content>
    </Message>
  );
}
