import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Icon, Grid, Text, Select } from "@jjordy/layout";
import { Formik, FastField as Field } from "formik";
import FormikField from "./FormikField";
import MaskedInput from "./MaskedInput";
import SSNInput from "./SSNInput";
import Recaptcha from "./Recaptcha";
import PasswordInput from "./PasswordInput";

const createIntialValues = (v, initialValues) => Object.assign({}, {}, v);

const hasInitialValue = (obj, prop) => {
  if (obj && typeof obj[prop] !== "undefined") {
    return true;
  }
  return false;
};

const createValidations = (validations) => (values) => {
  const errors = {};
  if (validations) {
    return validations(values, errors);
  } else {
    return errors;
  }
};

export default function SignupForm({
  submit,
  initialValues,
  validations,
  extraFields,
  loading,
  states,
  countries,
}) {
  return (
    <Formik
      onSubmit={submit}
      validate={createValidations(validations)}
      initialValues={createIntialValues(initialValues)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <Text strong fg="primary">
            Personal
          </Text>
          <Grid width="25%" gap={8}>
            {hasInitialValue(initialValues, "firstName") && (
              <Field
                component={FormikField}
                required
                type="text"
                name="firstName"
                label="First Name"
                placeholder="First Name"
              />
            )}
            {hasInitialValue(initialValues, "lastName") && (
              <Field
                component={FormikField}
                type="text"
                required
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
              />
            )}
            {hasInitialValue(initialValues, "email") && (
              <Field
                component={FormikField}
                type="email"
                name="email"
                required
                label="Email Address"
                placeholder="youremail@email.com"
              />
            )}
            {hasInitialValue(initialValues, "username") && (
              <Field
                component={FormikField}
                type="text"
                name="username"
                required
                label="Username"
                placeholder="Username"
              />
            )}
          </Grid>
          <Grid width="33%" gap={8}>
            {hasInitialValue(initialValues, "password") && (
              <Field
                component={FormikField}
                type="password"
                required
                name="password"
                As={PasswordInput}
                label="Desired Password"
                placeholder="********"
              />
            )}
            {hasInitialValue(initialValues, "confirmPassword") && (
              <Field
                component={FormikField}
                type="password"
                name="confirmPassword"
                As={PasswordInput}
                required
                label="Confirm Password"
                placeholder="********"
              />
            )}
            {hasInitialValue(initialValues, "ssn") && (
              <SSNInput
                name="ssn"
                required
                onChange={(v) => setFieldValue("ssn", v)}
                error={touched["ssn"] && errors["ssn"]}
              />
            )}
          </Grid>
          <Grid width="50%" gap={8}>
            {hasInitialValue(initialValues, "phone") && (
              <Field
                component={FormikField}
                As={MaskedInput}
                mask="(999) 999 9999"
                type="text"
                name="phone"
                required
                label="Phone Number"
                placeholder="(***) *** ****"
              />
            )}
            {hasInitialValue(initialValues, "dob") && (
              <Field
                component={FormikField}
                As={MaskedInput}
                mask="99/99/9999"
                type="text"
                name="dob"
                required
                label="Date Of Birth"
                placeholder="**/**/****"
              />
            )}
          </Grid>
          <Grid width="50%" gap={8}>
            {hasInitialValue(initialValues, "address1") && (
              <Field
                component={FormikField}
                type="text"
                name="address1"
                label="Current Address"
                required
                placeholder="1234 Global way"
              />
            )}
            {hasInitialValue(initialValues, "address2") && (
              <Field
                component={FormikField}
                type="text"
                name="address2"
                label="Address (Line 2)"
                placeholder="Building C"
              />
            )}
          </Grid>
          <Grid width="33%" gap={8}>
            {hasInitialValue(initialValues, "city") && (
              <Field
                component={FormikField}
                required
                type="text"
                name="city"
                label="City"
                placeholder="Gulfport"
              />
            )}
            {hasInitialValue(initialValues, "state") && (
              <Field
                component={FormikField}
                As={Select}
                name="state"
                label="State"
                placeholder="ms"
                required
                options={states || []}
              />
            )}
            {hasInitialValue(initialValues, "zip") && (
              <Field
                component={FormikField}
                type="text"
                required
                name="zip"
                label="Zip"
                placeholder="39507"
              />
            )}
          </Grid>
          {hasInitialValue(initialValues, "country") && (
            <Field
              component={FormikField}
              As={Select}
              name="country"
              required
              label="Country"
              placeholder="US"
              options={countries || []}
            />
          )}
          {extraFields({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          })}
          <Recaptcha
            render={({ isHuman }) => (
              <Button
                as="button"
                type="submit"
                fluid
                disabled={!isHuman || loading}
                rounded
              >
                <Button.Content>SIGNUP</Button.Content>
                <Button.Icon>
                  <Icon icon="chevron_right" bg="white" />
                </Button.Icon>
              </Button>
            )}
          />
        </Form>
      )}
    </Formik>
  );
}

SignupForm.propTypes = {
  loading: PropTypes.bool,
  submit: PropTypes.func.isRequired,
  extraFields: PropTypes.func,
  initialValues: PropTypes.object,
  validations: PropTypes.func,
  states: PropTypes.array,
  countries: PropTypes.array,
};

SignupForm.defaultProps = {
  extraFields: () => {},
};
