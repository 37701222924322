import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { Message, Text } from "@jjordy/layout";
import { FadeIn } from "components/Elements/Animation";
export default function NetworkError({ networkError = null }) {
  const [visible, setVisible] = useState(false);
  const strError = get(networkError, "response.data.error", null);
  const strError2 = get(networkError, "response.data.errors", null);
  const validationErrors = get(networkError, "response.data.errors.Errors", []);
  const csrfError = get(networkError, "response.data.message");
  const isCsrf = networkError && networkError.statusCode === 401;
  useEffect(() => {
    setVisible(networkError !== null);
  }, [networkError]);
  if (visible && networkError) {
    if (strError) {
      return (
        <Message bg="red" p={0.5} onClose={() => setVisible(null)}>
          <Text fg="white" strong m={0}>
            ERROR: {strError}
          </Text>
        </Message>
      );
    }
    if (
      strError2 &&
      !Array.isArray(strError2) &&
      typeof strError2 !== "object"
    ) {
      return (
        <Message bg="red" p={0.5} onClose={() => setVisible(null)}>
          <Text fg="white" strong m={0}>
            ERROR: {strError2}
          </Text>
        </Message>
      );
    }
    if (validationErrors.length > 0) {
      return (
        <FadeIn>
          <div>
            {validationErrors.map((error, id) => (
              <ul key={`form_validation_server_error_${id}`}>
                <li>
                  <Text fg="red" strong m={0}>
                    ERROR: {error}
                  </Text>
                </li>
              </ul>
            ))}
          </div>
        </FadeIn>
      );
    }
    if (csrfError && isCsrf) {
      return (
        <Message bg="red" p={0.5} onClose={() => setVisible(null)}>
          <Text fg="white" strong m={0}>
            ERROR: {csrfError}
          </Text>
        </Message>
      );
    }
  }
  return null;
}

NetworkError.propTypes = {
  networkError: PropTypes.object,
};
