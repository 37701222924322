import React from "react";
import PropTypes from "prop-types";
import { renderRoutes } from "react-router-config";
import CenterContainer from "components/Elements/CenterContainer";
import { Segment } from "@jjordy/layout";
import ClientLogo from "components/Elements/ClientLogo";

export default function GCORE({ route }) {
  return (
    <CenterContainer pageTitle="Migrate Legacy GlobalCORE Account" width={1150}>
      <Segment attached="top" bg="primary">
        <ClientLogo
          centered={false}
          theme={{
            logo: "https://globalfas2.s3.us-east-2.amazonaws.com/GlobalCore_Logo.png",
          }}
        />
      </Segment>
      <Segment attached="bottom">
        <React.Fragment>{renderRoutes(route.routes)}</React.Fragment>
      </Segment>
    </CenterContainer>
  );
}

GCORE.propTypes = {
  route: PropTypes.object,
};
