import { handleActions, createAction } from "redux-actions";

export const setTheme = createAction("SET_THEME");

const initialState = {};

export default handleActions(
  {
    [setTheme]: (state, { payload }) => ({
      ...payload,
    }),
  },
  initialState
);
