import { handleActions, createAction } from "redux-actions";
import http from "../http";
import { push } from "connected-react-router";

const loginReq = createAction("LOGIN_REQ");
const loginSuc = createAction("LOGIN_SUC");
const loginFail = createAction("LOGIN_FAIL");

const setLoginURL = createAction("SET_LOGIN_URL");

const setTokenSync = createAction("SET_TOKEN");

const setMigrationInfo = createAction("SET_MIGRATION_INFORMATION");

const api = http();

export const setToken = (token) => {
  return async (dispatch) => {
    dispatch(setTokenSync(token));
  };
};

export const login = (url, user) => {
  return async (dispatch, getState) => {
    const query = getState().query;
    dispatch(setLoginURL(url));
    dispatch(loginReq());
    try {
      const { data } = await api.post(url, {
        ...user,
        nextApp: query && query.nextApp ? query.nextApp : null,
      });
      dispatch(loginSuc(data));
      if (data.requiresTwoFactor) {
        // this account requires 2 factor authentication show 2 factor screen
        dispatch(push("/two-factor"));
      } else if (
        data.accountStatusCode &&
        data.accountStatusCode === 2 &&
        data.migration
      ) {
        // this account needs to be migrated
        dispatch(setMigrationInfo(data.migration));
        dispatch(push(data.migration.redirect));
        // this account needs to be redirected
      } else if (data.redirectURL) {
        if (user.schoolId) {
          dispatch(push(`/ssite/${user.schoolId}/login/success`));
        } else {
          dispatch(push("/login/success"));
        }
        window.location = data.redirectURL;
      } else if (data.multiAccountSelection) {
        // this account is an SSITE PARENT ACCOUNT AND
        // needs a second screen to select the redirect student
        dispatch(push("/ssite/multi-account-select"));
      } else {
        dispatch(push("/login/success"));
      }
    } catch (err) {
      if (err.response.status === 404 || err.response.status === 400) {
        dispatch(loginFail(err));
        throw new Error(
          "Username or password combination does not match or username not found."
        );
      } else if (err.response.status === 403) {
        dispatch(loginFail(err));
        throw new Error(
          "You have exceeded the number of times you may attempt to log in. You may retry logging in after 30 minutes."
        );
      } else {
        dispatch(loginFail(err));
        throw new Error(err.message);
      }
    }
  };
};

export const post2FALogin = () => {
  return async (dispatch, getState) => {
    const user = getState().login.data.loginInfo;
    const url = getState().login.loginURL;
    dispatch(loginReq());
    try {
      const { data } = await api.post(url, user);
      if (data.multiAccountSelection) {
        dispatch(loginSuc(data));
        dispatch(push("/ssite/multi-account-select"));
      } else {
        dispatch(loginSuc(data));
        if (user.schoolId) {
          dispatch(push(`/ssite/${user.schoolId}/login/success`));
        } else {
          dispatch(push("/login/success"));
        }
      }
      if (data.redirectURL) {
        window.location = data.redirectURL;
      }
    } catch (err) {
      dispatch(loginFail(err));
    }
  };
};

export const multiAccountSelectLogin = (studentId) => {
  return async (dispatch, getState) => {
    const user = getState().login.data.loginInfo;
    dispatch(loginReq());
    try {
      const { data } = await api.post(
        "/legacy/ssite/parent/generateLegacyLoginUrl",
        {
          ...user,
          studentId,
        }
      );
      dispatch(push("/login/success"));
      if (data.redirectURL) {
        window.location = data.redirectURL;
      }
      dispatch(loginSuc(data));
    } catch (err) {
      dispatch(loginFail(err));
    }
  };
};

const initialState = {
  data: {},
  error: null,
  loading: false,
  requiresTwoFactor: false,
  multiAccountSelection: null,
  loginURL: "",
  token: null,
};

export default handleActions(
  {
    [loginReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [loginSuc]: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false,
    }),
    [loginFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [setLoginURL]: (state, { payload }) => ({
      ...state,
      loginURL: payload,
    }),
    [setMigrationInfo]: (state, { payload }) => ({
      ...state,
      migration: payload,
    }),
    [setTokenSync]: (state, { payload }) => ({
      ...state,
      token: payload,
    }),
  },
  initialState
);
