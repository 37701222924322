import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Flex, Segment, Text } from "@jjordy/layout";
import { MapMarker, CheckSquareO } from "components/Elements/Icons";

const FinishedIcon = styled(CheckSquareO)`
  fill: ${(props) => props.theme.colors.success};
  margin-left: 1rem;
`;

const InProgressIcon = styled(MapMarker)`
  fill: ${(props) => props.theme.colors.primary};
  margin-left: 1rem;
`;

const CurrentIcon = ({ step, index }) => {
  if (step === index) {
    return <InProgressIcon />;
  } else if (step < index) {
    return null;
  } else if (step > index) {
    return <FinishedIcon />;
  }
};

const Panel = ({ setConfirm, step, index, text, link }) => {
  const prev = index <= 0 ? 1 : index;
  return (
    <Flex
      justify="space-between"
      role="button"
      aria-label={`Go back to Step ${prev}`}
    >
      {!link ? (
        <Text small={step !== index} fg="grey" m={0} strong={step === index}>
          {text}
        </Text>
      ) : (
        <Link to={link}>{text}</Link>
      )}
      <CurrentIcon step={step} index={index} />
    </Flex>
  );
};

Panel.propTypes = {
  setConfirm: PropTypes.func,
  step: PropTypes.number,
  index: PropTypes.number,
  text: PropTypes.string,
};

export default function Progress({ step, setStep = () => {} }) {
  const [confirmOps, setConfirm] = useState({ confirm: false, prev: step });
  return (
    <div>
      <Segment attached="top">
        <Panel
          setConfirm={setConfirm}
          step={step}
          index={1}
          text="Verify Existing Login Information"
        />
      </Segment>
      <Segment attached>
        <Panel
          setConfirm={setConfirm}
          step={step}
          index={2}
          text="Signup for a new account."
        />
      </Segment>
      <Segment attached="bottom">
        <Panel
          setConfirm={setConfirm}
          step={step}
          index={3}
          text="Login to your new account"
        />
      </Segment>
    </div>
  );
}

Progress.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func,
};
