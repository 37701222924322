import React from "react";
// import PropTypes from "prop-types";
import { Text, Button, Flex } from "@jjordy/layout";
import { useLocation } from "../../../../hooks/useRouter";

export default function Step2() {
  const { navigate } = useLocation();
  return (
    <Flex direction="column" align="center">
      <Text fg="grey" strong>
        Account Migration Successful
      </Text>
      <Button onClick={() => navigate("/")} fluid>
        Click here to login.
      </Button>
    </Flex>
  );
}

Step2.propTypes = {};
