import React from "react";
import PropTypes from "prop-types";
import { Form, Divider, Button, Icon } from "@jjordy/layout";
import Recaptcha from "components/Forms/Recaptcha";
import { Formik, FastField as Field } from "formik";
import {
  createValidator,
  required,
  oneNum,
  oneCap,
  oneSpecialChar,
  minLength,
  match,
  opeid
} from "utils/validations";
import FormikField from "components/Forms/FormikField";
import PasswordInput from "components/Forms/PasswordInput";

const validate = createValidator({
  password: [required, minLength(8), oneNum, oneSpecialChar, oneCap],
  confirmPassword: [required, match("password", "Passwords")],
  tenantId: [required, opeid]
});

const validateNoTenant = createValidator({
  password: [required, minLength(8), oneNum, oneSpecialChar, oneCap],
  confirmPassword: [required, match("password", "Passwords")]
});

export default function ChangePasswordForm({ submit, loading, schoolId }) {
  return (
    <Formik
      onSubmit={submit}
      initialValues={{
        password: "",
        confirmPassword: "",
        tenantId: schoolId || ""
      }}
      validate={(values) => {
        const errors = !schoolId ? validate(values) : validateNoTenant(values);
        const tenantRequired = required(values.tenantId);
        if (!schoolId && tenantRequired) {
          errors.tenantId = tenantRequired;
        }
        return errors;
      }}
      render={({
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        isSubmitting,
        handleChange
      }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <Field
            component={FormikField}
            As={PasswordInput}
            required
            type="password"
            label="Password"
            name="password"
          />
          <Field
            component={FormikField}
            As={PasswordInput}
            required
            type="password"
            label="Confirm Password"
            name="confirmPassword"
          />
          {!schoolId && (
            <Field
              component={FormikField}
              required
              type="text"
              label="OPEID"
              name="tenantId"
            />
          )}
          <Divider />
          <Recaptcha
            render={({ isHuman }) => (
              <Button type="submit" fluid disabled={!isHuman || loading}>
                <Button.Content>Submit</Button.Content>
                <Button.Icon>
                  <Icon icon="chevron_right" />
                </Button.Icon>
              </Button>
            )}
          />
        </Form>
      )}
    />
  );
}

ChangePasswordForm.propTypes = {
  submit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  schoolId: PropTypes.string
};
