import React from "react";
import { Segment, Header, Message, Button } from "@jjordy/layout";
import WithStore from "components/WithStore";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import { push } from "connected-react-router";

const getState = (state) => ({
  corpId: state.school.corpId,
  schoolId: state.school.schoolId,
  loading: state.changePassword.loading,
});

export default function ChangePasswordError() {
  return (
    <CenterContainer pageTitle="Change Password Error">
      <WithStore
        selector={getState}
        render={({ corpId, loading, schoolId }, dispatch) => (
          <React.Fragment>
            <Segment attached="top">
              <ClientLogo corp={corpId} />
              <Header fg="grey" textAlign="center">
                CHANGE PASSWORD ERROR
              </Header>
            </Segment>
            <Segment attached="bottom" textAlign="center">
              <Message
                bg="red"
                header="ERROR"
                icon="exclamation circle"
                content={
                  "The Link you are trying to use is invalid, please initiate another password reset via the website."
                }
              />
              <Button
                fluid
                type="button"
                onClick={() => dispatch(push("/forgot-password"))}
                content="GO BACK"
                icon="chevron right"
                labelPosition="right"
              />
            </Segment>
          </React.Fragment>
        )}
      />
    </CenterContainer>
  );
}
