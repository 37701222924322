import React from "react";
import { Segment, Header } from "@jjordy/layout";
import {
  changePassword,
  validateChangePasswordToken,
} from "redux/modules/changePassword";
import WithStore from "components/WithStore";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import ChangePasswordForm from "./ChangePasswordForm";
import ErrorDisplay from "components/Errors/ErrorDisplay";
import Component from "components/Component";

import { getSchoolId, getQueryParams } from "redux/selectors";

const getState = (state) => ({
  schoolId: getSchoolId(state),
  loading: state.changePassword.loading,
  params: getQueryParams(state),
});

export default function ChangePassword() {
  return (
    <CenterContainer pageTitle="Change Password">
      <WithStore
        selector={getState}
        render={({ loading, schoolId, params }, dispatch) => (
          <Component
            componentDidMount={() =>
              dispatch(validateChangePasswordToken(params))
            }
            render={() => (
              <React.Fragment>
                <Segment attached="top">
                  <ClientLogo />
                  <Header fg="primary" textAlign="center">
                    CHANGE PASSWORD
                  </Header>
                </Segment>
                <Segment attached="bottom">
                  <ErrorDisplay>
                    {({ setError }) => (
                      <ChangePasswordForm
                        loading={loading}
                        submit={(values) => {
                          dispatch(
                            changePassword({ ...values, ...params })
                          ).catch((err) =>
                            setError({ errorMessage: err.message })
                          );
                        }}
                        schoolId={schoolId}
                      />
                    )}
                  </ErrorDisplay>
                </Segment>
              </React.Fragment>
            )}
          />
        )}
      />
    </CenterContainer>
  );
}
