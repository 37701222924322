import { handleActions, createAction } from "redux-actions";
import api from "../http";
export const fetchConstantsReq = createAction("FETCH_CONSTANTS_REQ");
export const fetchConstantsSuc = createAction("FETCH_CONSTANTS_SUC");
export const fetchConstantsFail = createAction("FETCH_CONSTANTS_FAIL");

const initialState = {
  errors: [],
  loading: false,
};

export const fetchEnumerations = (constants = []) => {
  return async (dispatch) => {
    for (let c of constants) {
      dispatch(fetchConstantsReq());
      try {
        const { data } = await api().get(`/enumerations/${c}`);
        dispatch(fetchConstantsSuc(data));
      } catch (err) {
        dispatch(fetchConstantsFail(err));
      }
    }
  };
};

export default handleActions(
  {
    [fetchConstantsReq]: (state, { payload }) => ({
      ...state,
      loading: true,
    }),
    [fetchConstantsSuc]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [fetchConstantsFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      errors: [...state.errors, ...payload],
    }),
  },
  initialState
);
