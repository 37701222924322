import React from "react";
import { withTheme } from "styled-components";
import Link from "../QueryStateLink";
import { Image as RegularImage } from "@jjordy/layout";

const largeLogosIds = [66, 121, 127, 131, 132, 134];

export function ClientLogo({
  theme,
  size = "small",
  centered = true,
  ...rest
}) {
  // hack for now for SSITE-1753
  // TODO FIX THIS IN NEXT VERSION BETTER
  const Wrapper = ({ children }) => {
    if (theme.corpId && theme.corpId === 66) {
      return (
        <div
          style={{
            // backgroundColor: theme.colors.primary,
            padding: "1rem",
            borderRadius: 15.6,
          }}
        >
          {children}
        </div>
      );
    }
    return children;
  };
  return (
    <Wrapper>
      <Link to="/" ignoreQS>
        <RegularImage
          src={theme.logo || "/logo.png"}
          centered={centered}
          size={largeLogosIds.includes(theme.corpId) ? "large" : size}
          alt="LOGO Image"
          {...rest}
        />
      </Link>
    </Wrapper>
  );
}

export default withTheme(ClientLogo);
