import React from "react";
import WithStore from "components/WithStore";
import { migrateParentAccount } from "redux/modules/migration";
import { Header, Segment, Text, Button, Icon } from "@jjordy/layout";
import MigrationForm from "./ParentMigrationForm";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import ErrorDisplay from "components/Errors/ErrorDisplay";
import Link from "components/QueryStateLink";
import { Redirect } from "react-router-dom";

const getState = (state) => ({
  corpId: state.school.corpId,
  name: state.school.name,
  loading: state.migration.loading,
  schoolId: state.school.schoolId,
});

export default function ParentMigration() {
  return (
    <CenterContainer
      pageTitle="Migrate Account - Parent"
      width={1150}
      style={{ maxWidth: 1150 }}
    >
      <WithStore
        selector={getState}
        render={({ corpId, name, loading, schoolId }, dispatch) => {
          if (corpId) {
            return (
              <React.Fragment>
                <Segment attached="top" bg="primary">
                  <ClientLogo />
                  <Header
                    children="ACCOUNT MIGRATION REQUIRED"
                    as="h3"
                    fg="primary"
                    textAlign="center"
                    dividing
                  />
                  <Text fg="grey">
                    Please create a username and password to comply with our new
                    username/password policy. You will need the 4 digit PIN that
                    was emailed to you to create your account. Use this username
                    and password to login to your account to complete any
                    required documents for your student’s financial aid package.
                  </Text>
                </Segment>
                <Segment attached="bottom">
                  <ErrorDisplay>
                    {({ setError }) => (
                      <MigrationForm
                        submit={(val) =>
                          dispatch(
                            migrateParentAccount({ ...val, schoolId })
                          ).catch((err) =>
                            setError({ errorMessage: err.message })
                          )
                        }
                        loading={loading}
                      />
                    )}
                  </ErrorDisplay>
                  <Button
                    as={Link}
                    to={"/parent-forgot-pin"}
                    labelPosition="right"
                    mt={1}
                  >
                    Forgot Pin
                  </Button>
                </Segment>
              </React.Fragment>
            );
          } else {
            return <Redirect to={`/ssite/${schoolId}/parent-login`} />;
          }
        }}
      />
    </CenterContainer>
  );
}
