import React from "react";
import WithStore from "components/WithStore";
import { migrateAdminAccount } from "redux/modules/migration";
import { Header, Segment, Text } from "@jjordy/layout";
import MigrationForm from "./AdminMigrationForm";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import ErrorDisplay from "components/Errors/ErrorDisplay";
import { Redirect } from "react-router-dom";

const getState = (state) => ({
  corpId: state.school.corpId,
  name: state.school.name,
  loading: state.migration.loading,
  migration: state.login.migration,
  schoolId: state.school.schoolId,
});

export default function AdminMigration() {
  return (
    <CenterContainer pageTitle="Migrate Account - Admin">
      <WithStore
        selector={getState}
        render={({ corpId, name, loading, schoolId, migration }, dispatch) => {
          if (corpId && migration) {
            return (
              <React.Fragment>
                <Segment attached="top" bg="primary">
                  <ClientLogo />
                  <Header
                    children="ACCOUNT MIGRATION REQUIRED"
                    as="h3"
                    fg="primary"
                    textAlign="center"
                    dividing
                  />
                  <Text fg="grey">
                    Please update your <strong>password</strong> to comply with
                    our <strong>new password policy.</strong> This will enter
                    you into our new login system.
                  </Text>
                </Segment>
                <Segment attached="bottom">
                  <ErrorDisplay>
                    {({ setError }) => (
                      <MigrationForm
                        initialValues={migration}
                        submit={(val) =>
                          dispatch(
                            migrateAdminAccount({ ...val, schoolId })
                          ).catch((err) =>
                            setError({ errorMessage: err.message })
                          )
                        }
                        loading={loading}
                      />
                    )}
                  </ErrorDisplay>
                </Segment>
              </React.Fragment>
            );
          } else {
            return <Redirect to={`/ssite/${schoolId}/admin-login`} />;
          }
        }}
      />
    </CenterContainer>
  );
}
