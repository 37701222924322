import React from "react";
import PropTypes from "prop-types";
import WithStore from "components/WithStore";
import CenterContainer from "components/Elements/CenterContainer";
import { fetchEnumerations } from "redux/modules/enumerations";
import { loadPrograms, loadStartDates } from "redux/modules/school";
import Component from "components/Component";
import Signups from "./Signups";
import { getCorpId, getSchoolId } from "redux/selectors";

export default function SignupContainer({ match }) {
  return (
    <CenterContainer
      pageTitle="Signup"
      width={match.params.school ? 1150 : 750}
    >
      <WithStore
        selector={getState}
        render={(props, dispatch) => {
          return (
            <Component
              componentDidMount={() =>
                handleMount({ match, ...props, dispatch })
              }
              render={() => (
                <Signups {...props} match={match} dispatch={dispatch} />
              )}
            />
          );
        }}
      />
    </CenterContainer>
  );
}

const getState = (state) => ({
  corpId: getCorpId(state),
  loaded: state.school.loaded,
  schoolId: getSchoolId(state),
  loading: state.signup.loading,
  name: state.school.name,
  states: state.enumerations.states,
  countries: state.enumerations.countries,
  schoolLoading: state.school.loading,
  bestTimeToCall: state.enumerations.bestTimeToCall,
  programs: state.school.programs,
  startdates: state.school.startdates,
});

const handleMount = async ({
  states,
  countries,
  match,
  programs,
  dispatch,
}) => {
  if (!states || !countries) {
    await dispatch(
      fetchEnumerations(["states", "countries", "bestTimeToCall"])
    );
  }
  if (match.params.school && !programs) {
    const { programs } = await dispatch(loadPrograms(match.params.school));
    await dispatch(loadStartDates(match.params.school, programs[0].scheduleId));
  }
};

SignupContainer.propTypes = {
  match: PropTypes.object,
};

SignupContainer.loadData = async ({ match, store }) => {
  try {
    await store.dispatch(
      fetchEnumerations(["states", "countries", "bestTimeToCall"])
    );
    if (match.params.school) {
      const { programs } = await store.dispatch(
        loadPrograms(match.params.school)
      );
      await store.dispatch(
        loadStartDates(match.params.school, programs[0].scheduleId)
      );
    }
  } catch (err) {
    console.log(err);
  }
};
