import { createSelector } from "reselect";
// this selector gets the userId from the token data sent back by the api
// the api will only return the token if 2fa is not required
// example the user has already completed 2fa and has a cookie that isnt expired.
const userDataSelector = (state) => state.login.data;

export const getUserId = createSelector(userDataSelector, (data) => {
  if (data && data.userInfo && data.userInfo.sub) {
    return data.userInfo.sub;
  } else {
    return null;
  }
});
// this selector checks both login reducer and two factor
// reducers to see if either are working currently.
const loginLoading = (state) => state.login.loading;
const twofactorLoading = (state) => state.twofactor.loading;

export const loading = createSelector(
  loginLoading,
  twofactorLoading,
  (i, e) => {
    return i || e;
  }
);

export const userHasPhone = createSelector(userDataSelector, (data) => {
  if (data && data.userInfo) {
    if (data.userInfo.phone_number) {
      return true;
    }
  }
  return false;
});
