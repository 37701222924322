import React from "react";
import { Segment, Header, Button, Text } from "@jjordy/layout";
import WithStore from "components/WithStore";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import Link from "components/QueryStateLink";

const getState = (state) => ({
  corpId: state.school.corpId,
  schoolId: state.school.schoolId,
  loading: state.changePassword.loading,
});

export default function ChangePasswordSuccess() {
  return (
    <CenterContainer pageTitle="Change Password Success">
      <WithStore
        selector={getState}
        render={({ corpId, loading, schoolId }, dispatch) => (
          <React.Fragment>
            <Segment attached="top">
              <ClientLogo corp={corpId} />
              <Header fg="primary" as="h5" textAlign="center">
                CHANGE PASSWORD SUCCESS
              </Header>
            </Segment>
            <Segment attached="bottom" textAlign="center">
              <Text fg="grey" mx={1}>
                Your password has successfully been changed. You may now login
                with your new password.
              </Text>
              <Button
                mb={1}
                as={Link}
                fluid
                rounded
                to="/"
                children={corpId ? "STUDENT LOGIN" : "LOGIN"}
                bg="primary"
                ignoreQS
              />
              {corpId && (
                <Button
                  mb={1}
                  as={Link}
                  fluid
                  rounded
                  to="/parent-login"
                  children="PARENT LOGIN"
                  ignoreQS
                />
              )}
              {corpId && (
                <Button
                  mb={1}
                  as={Link}
                  fluid
                  rounded
                  to="/admin-login"
                  children="ADMIN LOGIN"
                  ignoreQS
                />
              )}
            </Segment>
          </React.Fragment>
        )}
      />
    </CenterContainer>
  );
}
