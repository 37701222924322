import React from "react";
import CenterContainer from "components/Elements/CenterContainer";
import { Segment, Header, Dimmer, Loader } from "@jjordy/layout";
import ClientLogo from "components/Elements/ClientLogo";
import WithStore from "components/WithStore";
import Component from "components/Component";
import Redirect from "components/Redirect";
import AccountSelectionItem from "./AccountSelectionItem";
import { push } from "connected-react-router";

const getState = (state) => ({
  multiAccountSelection: state.login.data.multiAccountSelection,
  loading: state.login.loading,
});

export function MultiAccountSelect() {
  return (
    <CenterContainer pageTitle="Select an account">
      <WithStore
        selector={getState}
        render={({ multiAccountSelection, loading }, dispatch) => (
          <Component
            render={() => {
              if (Array.isArray(multiAccountSelection)) {
                return (
                  <Segment bg="primary" mt={2} clearing>
                    <Dimmer active={loading}>
                      <Loader active />
                    </Dimmer>
                    <ClientLogo />
                    <Header as="h5" fg="primary" textAlign="center">
                      Select an Account
                    </Header>
                    {multiAccountSelection.map((account, i) => (
                      <AccountSelectionItem
                        key={i}
                        id={i}
                        total={multiAccountSelection.length}
                        account={account}
                        setAccount={(studentId) =>
                          dispatch(
                            push(
                              `/ssite/multi-account-select/verify/${studentId}`
                            )
                          )
                        }
                      />
                    ))}
                  </Segment>
                );
              }
              return <Redirect to="/parent-login" />;
            }}
          />
        )}
      />
    </CenterContainer>
  );
}

export default MultiAccountSelect;
