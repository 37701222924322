import { handleActions, createAction } from "redux-actions";
import http from "../http";
import { push } from "connected-react-router";
import { createMessage } from "./message";

export const loadInvReq = createAction("LOAD_INVITE_REQ");
export const loadInvSuc = createAction("LOAD_INVITE_SUC");
export const loadInvFail = createAction("LOAD_INVITE_FAIL");

export const registerInvReq = createAction("REGISTER_INVITE_REQ");
export const registerInvSuc = createAction("REGISTER_INVITE_SUC");
export const registerInvFail = createAction("REGISTER_INVITE_FAIL");

export const inviteExpired = createAction("REGISTER_INVITE_EXPIRED");
export const inviteCancelled = createAction("REGISTER_INVITE_CANCELLED");

const api = http();

export const loadInvite = (id) => {
  return async (dispatch) => {
    dispatch(loadInvReq());
    try {
      const { data } = await api.get(`/invites/${id}`);
      dispatch(loadInvSuc(data));
    } catch (err) {
      if (err && err.response && err.response) {
        if (err.response.status === 404) {
          dispatch(inviteCancelled());
        } else if (err.response.status === 400) {
          dispatch(inviteExpired());
        }
        dispatch(loadInvFail(err.response));
      } else {
        dispatch(loadInvFail("Something went wrong"));
      }
    }
  };
};

export const registerInvite = (member, id) => {
  return async (dispatch) => {
    dispatch(registerInvReq());
    try {
      const { data } = await api.post(`/invites/${id}`, member);
      dispatch(registerInvSuc(data));
      dispatch(push("/"));
      dispatch(
        createMessage({
          children: "Congratulations you can now login with your new account",
          color: "primary",
        })
      );
    } catch (err) {
      if (err && err.response && err.response.data) {
        dispatch(registerInvFail(err.response.data));
      } else {
        dispatch(registerInvFail("Something went wrong"));
      }
    }
  };
};

const initialState = {
  loading: false,
  data: null,
  error: null,
  registrationResponse: null,
};

export default handleActions(
  {
    [loadInvReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [loadInvSuc]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
    [loadInvFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [registerInvReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [registerInvSuc]: (state, { payload }) => ({
      ...state,
      loading: false,
      registrationResponse: payload,
    }),
    [registerInvFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [inviteCancelled]: (state, { payload }) => ({
      ...state,
      cancelled: true,
    }),
    [inviteExpired]: (state, { payload }) => ({
      ...state,
      expired: true,
    }),
  },
  initialState
);
