import React from "react";
import PropTypes from "prop-types";
import { Formik, Field } from "formik";
import { Form, Button, Checkbox, Grid, Text } from "@jjordy/layout";
import FormikField from "components/Forms/FormikField";
import PasswordInput from "components/Forms/PasswordInput";
import Center from "components/Elements/Center";
import Legal from "components/Legal";
import {
  createValidator,
  required,
  minLength,
  maxLength,
  match,
  oneNum,
  oneCap,
  oneSpecialChar,
} from "@jjordy/validation";

const validate = createValidator({
  username: [required],
  password: [
    required,
    minLength(8),
    maxLength(25),
    oneNum,
    oneSpecialChar,
    oneCap,
  ],
  confirmPassword: [required, match("password", "Passwords")],
  firstName: [required],
  lastName: [required],
  agreeTOS: (val) => {
    if (!val) {
      return "You must agree to the Terms of Service and privacy policy first.  ";
    }
  },
});

export default function InviteForm({ initialValues, submit, loading }) {
  return (
    <Formik
      initialValues={Object.assign({}, initialValues, {
        password: "",
        confirmPassword: "",
        agreeTOS: false,
        username: "",
      })}
      onSubmit={submit}
      validate={(values) => {
        const errors = validate(values);
        return errors;
      }}
    >
      {({ handleSubmit, setFieldValue, errors, touched }) => (
        <Form onSubmit={handleSubmit}>
          <Field
            component={FormikField}
            name="username"
            label="Username"
            required
          />
          <Grid width="50%">
            <Field
              component={FormikField}
              name="firstName"
              label="First Name"
              required
            />
            <Field
              component={FormikField}
              name="lastName"
              label="Last Name"
              required
            />
          </Grid>
          <Grid width="50%">
            <Field
              component={FormikField}
              name="password"
              As={PasswordInput}
              label="Password"
              required
              type="password"
            />
            <Field
              component={FormikField}
              As={PasswordInput}
              name="confirmPassword"
              label="Confirm Password"
              required
              type="password"
            />
          </Grid>
          <Legal />
          <Center>
            <Field
              name="agreeTOS"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  onChange={(v) => setFieldValue("agreeTOS", v)}
                  label="I Agree"
                  bg="primary"
                  required
                />
              )}
            />
            <br />
          </Center>
          {touched && errors && errors.agreeTOS && (
            <Center>
              <Text fg="red">You must agree to the terms of service</Text>
            </Center>
          )}
          <br />
          <br />
          <Button type="submit" fluid bg="primary" disabled={loading}>
            Register
          </Button>
        </Form>
      )}
    </Formik>
  );
}

InviteForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
};
