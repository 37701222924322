import React from "react";
import PropTypes from "prop-types";
import { Formik, FastField as Field } from "formik";
import FormikField from "components/Forms/FormikField";
import { Form, Button, Divider, Icon } from "@jjordy/layout";
import {
  createValidator,
  minLength,
  oneCap,
  oneSpecialChar,
  oneNum,
  required,
  match,
} from "@jjordy/validation";

const validate = createValidator({
  oldPassword: [required],
  newPassword: [required, minLength(8), oneCap, oneSpecialChar, oneNum],
  confirmPassword: [required, match("newPassword", "Passwords")],
});

export default function UpdatePasswordForm({ submit, loading }) {
  return (
    <Formik
      onSubmit={submit}
      validate={(values) => {
        const errors = validate(values);
        return errors;
      }}
      initialValues={{ oldPassword: "", newPassword: "", confirmPassword: "" }}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <Field
            component={FormikField}
            name="oldPassword"
            type="password"
            label="Enter your existing Password"
            placeholder="**********"
          />
          <Field
            component={FormikField}
            name="newPassword"
            type="password"
            label="Enter your new Password"
            placeholder="**********"
          />
          <Field
            component={FormikField}
            name="confirmPassword"
            type="password"
            label="Confirm your new Password"
            placeholder="**********"
          />
          <Divider hidden my={2} />
          <Button type="submit" fluid disabled={loading} bg="primary" rounded>
            <Button.Content>UPDATE PASSWORD</Button.Content>
            <Button.Icon>
              <Icon icon="chevron_right" bg="white" />
            </Button.Icon>
          </Button>
        </Form>
      )}
    </Formik>
  );
}

UpdatePasswordForm.propTypes = {
  submit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
