import React, { useState } from "react";
import { Input, Button, Icon } from "@jjordy/layout";

export default function PasswordInput(props) {
  const [visible, setVisible] = useState(false);
  return (
    <Input
      {...props}
      type={visible ? "text" : "password"}
      autoComplete="current-password"
      action={() => (
        <Button
          onClick={() => setVisible(!visible)}
          type="button"
          bg="primary"
          id={`id_password_input_show_hide_button_${props.name}`} // eslint-disable-line
          labelPosition="right"
          title={visible ? "Hide" : "Show"}
        >
          <Icon icon={visible ? "eye" : "eye_slash"} />
        </Button>
      )}
    />
  );
}
