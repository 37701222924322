import React from "react";
import PropTypes from "prop-types";
import { Formik, FastField as Field } from "formik";
import { Form, Button, Divider } from "@jjordy/layout";
import FormikField from "components/Forms/FormikField";
import { createValidator, required, minLength } from "@jjordy/validation";
import PasswordInput from "components/Forms/PasswordInput";

const validate = createValidator({
  username: [required],
  password: [required, minLength(8)],
});

export default function MigrateForm({ onSubmit, loading }) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ username: "", password: "" }}
      validate={(values) => {
        const errors = validate(values);
        return errors;
      }}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <Field
            component={FormikField}
            name="username"
            label="Username"
            placeholder="Username"
          />
          <Field
            component={FormikField}
            As={PasswordInput}
            type="password"
            name="password"
            label="Password"
            placeholder="***********"
          />
          <Divider />
          <Button
            fluid
            type="submit"
            disabled={loading}
            content="START ACCOUNT MIGRATION"
          />
        </Form>
      )}
    </Formik>
  );
}

MigrateForm.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
