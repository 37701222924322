import React from "react";
import { Segment, Header } from "@jjordy/layout";
import { forgotPassword } from "redux/modules/forgotPassword";
import WithStore from "components/WithStore";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ErrorDisplay from "components/Errors/ErrorDisplay";
import { getSchoolId } from "redux/selectors";

const getState = (state) => ({
  schoolId: getSchoolId(state),
  loading: state.forgotPassword.loading,
});

export default function ForgotPassword() {
  return (
    <CenterContainer pageTitle="Forgot Password">
      <WithStore
        selector={getState}
        render={({ loading, schoolId }, dispatch) => (
          <React.Fragment>
            <Segment attached="top" bg="primary">
              <ClientLogo />
              <Header fg="primary" textAlign="center" as="h4">
                FORGOT PASSWORD
              </Header>
            </Segment>
            <Segment attached="bottom">
              <ErrorDisplay>
                {({ setError }) => (
                  <ForgotPasswordForm
                    loading={loading}
                    submit={(v) =>
                      dispatch(forgotPassword(v)).catch((err) =>
                        setError({ errorMessage: err.message })
                      )
                    }
                    schoolId={schoolId}
                  />
                )}
              </ErrorDisplay>
            </Segment>
          </React.Fragment>
        )}
      />
    </CenterContainer>
  );
}
