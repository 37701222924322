import React from "react";
import PropTypes from "prop-types";
import { Input } from "@jjordy/layout";

const FormikField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  As = Input,
  message,
  title,
  ...props
}) => (
  <div>
    <As
      {...field}
      {...props}
      title={title}
      error={touched[field.name] && errors[field.name]}
      message={(touched[field.name] && errors[field.name]) || message}
    />
  </div>
);

FormikField.propTypes = {
  field: PropTypes.object.isRequired,
  message: PropTypes.string,
  form: PropTypes.object.isRequired,
  title: PropTypes.string,
  As: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.node]),
};

export default FormikField;
