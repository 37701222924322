import React from "react";
import PropTypes from "prop-types";
import { Message, Fade } from "@jjordy/layout";

export default class ErrorDisplay extends React.Component {
  state = { error: false, errorMessage: "" };

  setErrorState = (props) => this.setState({ error: true, ...props });

  render() {
    return (
      <div>
        <Fade visible={this.state.error && this.state.errorMessage}>
          <Message
            bg="red"
            icon="question"
            onClose={() =>
              this.setErrorState({ error: false, errorMessage: "" })
            }
          >
            <Message.Content style={{ marginBottom: "1rem" }}>
              <Message.Header>ERROR</Message.Header>
              {this.state.errorMessage}
            </Message.Content>
          </Message>
        </Fade>
        {this.props.children({ setError: this.setErrorState })}
      </div>
    );
  }
}

ErrorDisplay.propTypes = {
  children: PropTypes.func.isRequired,
};

ErrorDisplay.defaultProps = {
  children: () => <div />,
};
