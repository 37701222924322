import { handleActions, createAction } from "redux-actions";
import { goBack } from "connected-react-router";
import { createMessage } from "./message";
import http from "../http";

const forgotPinReq = createAction("FORGOT_PIN_REQ");
const forgotPinSuc = createAction("FORGOT_PIN_SUC");
const forgotPinFail = createAction("FORGOT_PIN_FAIL");

const api = http();

export const forgotPin = (values) => {
  return async (dispatch) => {
    dispatch(forgotPinReq());
    try {
      const { data } = await api.post("/forgot-pin", values);
      dispatch(forgotPinSuc(data));
      dispatch(goBack());
      dispatch(
        createMessage({
          color: "primary",
          children: "Your pin has been emailed to you please check your email.",
        })
      );
    } catch (err) {
      dispatch(forgotPinFail(err));
      throw new Error("Email, SSN or Student's Date of Birth does not match");
    }
  };
};

const initialState = {
  data: null,
  error: null,
  loading: false,
};

export default handleActions(
  {
    [forgotPinReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [forgotPinSuc]: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false,
    }),
    [forgotPinFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
  initialState
);
