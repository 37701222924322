import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Segment, Header } from "@jjordy/layout";
import { forgotPin } from "redux/modules/forgotPin";
import WithStore from "components/WithStore";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import LegacyLoginForm from "../Logins/LegacyLoginForm";
import ErrorDisplay from "components/Errors/ErrorDisplay";
import { FastField as Field } from "formik";
import FormikField from "components/Forms/FormikField";
import MaskedInput from "components/Forms/MaskedInput";
import {
  createValidator,
  required,
  email,
  cleanMask,
  minLength,
  maxLength,
  dateMMDDYYYY,
} from "@jjordy/validation";
import SSNInput from "components/Forms/SSNInput";
import { getCorpId } from "redux/selectors";

const validate = createValidator({
  email: [required, email],
  ssn: [required, minLength(11), maxLength(11)],
  dob: [required, dateMMDDYYYY],
});

const getState = (state) => ({
  corpId: getCorpId(state),
  name: state.school.name,
  loading: state.forgotPin.loading,
});

export default function ParentForgotPin({ match }) {
  return (
    <CenterContainer pageTitle="PARENT LOGIN">
      <WithStore
        selector={getState}
        awaiting="corpId"
        render={({ corpId, name, loading }, dispatch) => (
          <React.Fragment>
            <Helmet title={`${name} Login`} />
            <Segment attached="top" bg="primary">
              <ClientLogo corp={corpId} />
              <Header
                children={`${name} | PARENT FORGOT PIN`}
                fg="primary"
                as="h5"
                textAlign="center"
              />
            </Segment>
            <Segment attached="bottom">
              <ErrorDisplay>
                {({ setError }) => (
                  <LegacyLoginForm
                    loading={loading}
                    loginText="FORGOT PIN"
                    school={match.params.school}
                    initialValues={{ email: "", ssn: "", dob: "" }}
                    labelOverrides={{ email: "PARENT'S EMAIL" }}
                    validations={(values, errors) => {
                      const e = validate(values);
                      return Object.assign({}, errors, e);
                    }}
                    login={(values) => {
                      const payload = Object.assign({}, values, {
                        ssn: cleanMask(values.ssn),
                      });
                      dispatch(forgotPin(payload)).catch((err) =>
                        setError({ error: true, errorMessage: err.message })
                      );
                    }}
                    extraFields={({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }) => (
                      <React.Fragment>
                        <Field
                          component={FormikField}
                          As={SSNInput}
                          style={{ width: "100%" }}
                          name="ssn"
                          label={"STUDENT'S SOCIAL SECURITY NUMBER"}
                          onChange={(v) => {
                            setFieldValue("ssn", v);
                            setFieldTouched("ssn");
                          }}
                          required
                        />
                        <Field
                          component={FormikField}
                          As={MaskedInput}
                          mask="99/99/9999"
                          required
                          type="text"
                          label="STUDENT'S DATE OF BIRTH"
                          placeholder="01/24/1986"
                          name="dob"
                          mb={1}
                        />
                      </React.Fragment>
                    )}
                  />
                )}
              </ErrorDisplay>
            </Segment>
          </React.Fragment>
        )}
      />
    </CenterContainer>
  );
}

ParentForgotPin.propTypes = {
  match: PropTypes.object.isRequired,
};
