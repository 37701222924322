import { handleActions, createAction } from "redux-actions";
import { push } from "connected-react-router";
import http from "../http";

const changePasswordReq = createAction("CHANGE_PASSWORD_REQ");
const changePasswordSuc = createAction("CHANGE_PASSWORD_SUC");
const changePasswordFail = createAction("CHANGE_PASSWORD_FAIL");

const validateChangePasswordTokenReq = createAction(
  "VALIDATE_CHANGE_PASSWORD_TOKEN_REQ"
);
const validateChangePasswordTokenSuc = createAction(
  "VALIDATE_CHANGE_PASSWORD_TOKEN_SUC"
);
const validateChangePasswordTokenFail = createAction(
  "VALIDATE_CHANGE_PASSWORD_TOKEN_FAIL"
);

const api = http();

export const validateChangePasswordToken = (values) => {
  return async (dispatch) => {
    dispatch(validateChangePasswordTokenReq());
    try {
      const { data } = await api.post("/change-password/validate", values);
      dispatch(validateChangePasswordTokenSuc(data));
    } catch (err) {
      dispatch(validateChangePasswordTokenFail(err));
      dispatch(push("/change-password/error"));
    }
  };
};

export const changePassword = (values) => {
  return async (dispatch) => {
    dispatch(changePasswordReq());
    try {
      const { data } = await api.post("/change-password", values);
      dispatch(changePasswordSuc(data));
      dispatch(push("/change-password/success"));
    } catch (err) {
      dispatch(changePasswordFail(err));
      if (err.response && err.response.status === 400) {
        throw new Error("Email address not found");
      }
    }
  };
};

const initialState = {
  data: null,
  error: null,
  loading: false,
};

export default handleActions(
  {
    [changePasswordReq]: (state) => ({
      ...state,
      loading: true,
    }),
    [changePasswordSuc]: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false,
    }),
    [changePasswordFail]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
  initialState
);
