import React from "react";
import { Segment, Icon, Text, Message } from "@jjordy/layout";
import CenterContainer from "components/Elements/CenterContainer";

const LinkUsed = () => (
  <CenterContainer pageTitle="Link used">
    <Segment mt={4} p={0}>
      <Message bg="error">
        <Message.Icon>
          <Icon icon="question" bg="white" size={2} />
        </Message.Icon>
        <Message.Content>
          <Message.Header>ERROR</Message.Header>
          The Link you are trying to access has already been used.
        </Message.Content>
      </Message>
      <Text fg="grey" p={1}>
        Please contact your Global Financial Aid Services support team at &nbsp;
        <br />
        <a href="mailto:DataCheckHelp@globalfas.com">
          DataCheckHelp@globalfas.com
        </a>
      </Text>
    </Segment>
  </CenterContainer>
);

export default LinkUsed;
