import React from "react";
import PropTypes from "prop-types";
import { Form, Divider, Text } from "@jjordy/layout";
import SubmitButton from "components/Elements/SubmitButton";
import { Formik, FastField as Field } from "formik";
import Recaptcha from "components/Forms/Recaptcha";
import FormikField from "components/Forms/FormikField";
import { createValidator, required, email, opeid } from "utils/validations";

const validate = createValidator({
  email: [required, email],
  tenantId: [required, opeid]
});

const validateNoTenant = createValidator({
  email: [required, email]
});

export default function ForgotUsernameForm({ submit, loading, schoolId }) {
  return (
    <Formik
      onSubmit={submit}
      initialValues={{ email: "", tenantId: schoolId }}
      validate={(values) => {
        const errors = !schoolId ? validate(values) : validateNoTenant(values);
        const tenantRequired = required(values.tenantId);
        if (!schoolId && tenantRequired) {
          errors.tenantId = tenantRequired;
        }
        return errors;
      }}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <Field
            component={FormikField}
            required
            type="text"
            label="Email"
            name="email"
            placeholder="Email"
          />
          {!schoolId && (
            <Field
              component={FormikField}
              required
              type="text"
              label="OPEID"
              placeholder="OPEID"
              name="tenantId"
            />
          )}
          <Text strong fg="darkGrey" my={1}>
            Please enter your email address and you will receive an email with
            your username.
          </Text>
          <Divider />
          <Recaptcha
            render={({ isHuman }) => (
              <SubmitButton disabled={!isHuman || loading} />
            )}
          />
        </Form>
      )}
    />
  );
}

ForgotUsernameForm.propTypes = {
  submit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  schoolId: PropTypes.string
};
