import React from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

const code = process.env.RECAPTCHA || __RECAPTCHA__;

export default class Recaptcha extends React.Component {
  state = { isHuman: false };

  onChange = (v) => {
    this.setState({ isHuman: true });
  };

  onExpired = (v) => {
    this.setState({ isHuman: false });
  };

  render() {
    const { isHuman } = this.state;
    return (
      <React.Fragment>
        <Container>
          <ReCAPTCHA
            sitekey={code}
            onChange={this.onChange}
            onExpired={this.onExpired}
          />
        </Container>
        {this.props.render({ isHuman })}
      </React.Fragment>
    );
  }
}

Recaptcha.propTypes = {
  render: PropTypes.func.isRequired,
};

Recaptcha.defaultProps = {
  render: () => {},
};
