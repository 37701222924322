import React from "react";
import PropTypes from "prop-types";
import { setToken } from "redux/modules/login";
import Component from "components/Component";
import WithStore from "components/WithStore";
import { push } from "connected-react-router";

const getState = (state) => ({ query: state.query, token: state.login.token });

export default function Auth({ match }) {
  return (
    <WithStore
      selector={getState}
      render={({ query, token }, dispatch) => (
        <Component
          componentDidMount={() => {
            if (match.params.token) {
              dispatch(setToken(match.params.token)).then(() =>
                dispatch(push(query.redirect))
              );
            }
          }}
          render={() => null}
        />
      )}
    />
  );
}

Auth.propTypes = { match: PropTypes.object.isRequired };
