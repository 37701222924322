import React from "react";
import { Segment, Header, Button, Text, Icon } from "@jjordy/layout";
import WithStore from "components/WithStore";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import { go } from "connected-react-router";

const getState = (state) => ({
  corpId: state.school.corpId,
  schoolId: state.school.schoolId,
});

export default function ForgotUsername() {
  return (
    <CenterContainer pageTitle="Forgot Username">
      <WithStore
        selector={getState}
        render={({ corpId, schoolId }, dispatch) => (
          <React.Fragment>
            <Segment attached="top" bg="primary">
              <ClientLogo corp={corpId} />
              <Header fg="grey" textAlign="center">
                FORGOT USERNAME SUCCESS
              </Header>
            </Segment>
            <Segment attached>
              <Text fg="grey">
                An email with your username has been sent to the email address
                on file.
              </Text>
            </Segment>
            <Segment attached="bottom">
              <Button
                onClick={() => dispatch(go(-2))}
                bg="primary"
                fluid
                rounded
              >
                <Button.Content>BACK TO LOGIN</Button.Content>
                <Button.Icon>
                  <Icon icon="chevron_right" />
                </Button.Icon>
              </Button>
            </Segment>
          </React.Fragment>
        )}
      />
    </CenterContainer>
  );
}
