import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const CenteredContainer = styled.div`
  display: flex;
  @media (min-width: 768px) {
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    width: 100%;
    min-height: 100%;
  }
`;

const ChildContainer = styled.div`
  @media (min-width: 768px) {
    width: ${(props) => (props.width ? props.width : "500")}px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default function CenterContainer({ pageTitle, children, width }) {
  return (
    <div>
      <Helmet title={pageTitle} />
      <CenteredContainer>
        <ChildContainer width={width}>{children}</ChildContainer>
      </CenteredContainer>
    </div>
  );
}

CenterContainer.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
};
