import React from "react";
import CenterContainer from "components/Elements/CenterContainer";
import { Segment, Header, Dimmer, Loader, Label } from "@jjordy/layout";
import ClientLogo from "components/Elements/ClientLogo";
import WithStore from "components/WithStore";
import Component from "components/Component";
import { redirectDependent } from "redux/modules/profile";
import Redirect from "components/Redirect";
import ConfirmStudentInfoForm from "components/Forms/ConfirmStudentInfo";

const getState = (state) => ({
  profile: state.profile.data,
  loading: state.profile.loading,
  token: state.login.token,
});

export function VerifyStudentAccount({ match }) {
  return (
    <CenterContainer pageTitle="Select an account">
      <WithStore
        selector={getState}
        render={({ profile, loading, token }, dispatch) => (
          <Component
            render={() => {
              if (
                profile &&
                profile.multiAccountSelection &&
                Array.isArray(profile.multiAccountSelection)
              ) {
                const selectedStudent = profile.multiAccountSelection.find(
                  (s) => s.studentId === parseInt(match.params.id, 10)
                );
                if (selectedStudent) {
                  return (
                    <Segment bg="primary" mt={2} clearing>
                      <Dimmer active={loading}>
                        <Loader active />
                      </Dimmer>
                      <ClientLogo />
                      <Header as="h5" fg="primary" textAlign="center">
                        Verify Student Information for
                        <br />
                        <br />
                        <Label>
                          {selectedStudent.firstName} -{" "}
                          {selectedStudent.lastName}
                        </Label>
                      </Header>
                      <ConfirmStudentInfoForm
                        onSubmit={() =>
                          dispatch(
                            redirectDependent(token, selectedStudent.studentId)
                          )
                        }
                        selectedStudent={selectedStudent}
                      />
                    </Segment>
                  );
                }
              }
              return <Redirect to="/parent-login" />;
            }}
          />
        )}
      />
    </CenterContainer>
  );
}

export default VerifyStudentAccount;
