import axios from "axios";
import { isNode } from "../utils";

export default function api() {
  const baseURL = !isNode()
    ? "/api"
    : `http://${process.env.HOSTNAME}:${process.env.PORT}/api`;
  return axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
    transformRequest: (data) => JSON.stringify(data),
  });
}
