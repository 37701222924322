import React from "react";
import PropTypes from "prop-types";
import { Header, Segment } from "@jjordy/layout";
import { signup } from "redux/modules/signup";
import SSITESignup from "../SSITE/Signups";
import NextgenSignup from "../Nextgen/Signups";
import ClientLogo from "components/Elements/ClientLogo";
import ErrorDisplay from "components/Errors/ErrorDisplay";
import { loadStartDates } from "redux/modules/school";

const Signups = ({
  schoolLoading,
  loading,
  match,
  corpId,
  schoolId,
  programs,
  countries,
  startdates,
  states,
  bestTimeToCall,
  dispatch,
}) => (
  <Segment bg="primary" my={2} clearing loading={schoolLoading || loading}>
    <ClientLogo />
    <Header
      children={"Sign up for an account"}
      as="h3"
      fg="primary"
      textAlign="center"
      dividing
    />
    <ErrorDisplay>
      {({ setError }) => (
        <React.Fragment>
          {match.params.school && corpId ? (
            <SSITESignup
              onSubmit={handleLegacySubmit({ dispatch, schoolId, setError })}
              countries={countries}
              loading={loading}
              states={states}
              startdates={startdates}
              schoolLoading={schoolLoading}
              programs={programs}
              bestTimeToCall={bestTimeToCall}
              loadStartDates={handleRefreshStartDates({
                dispatch,
                match,
                programs,
              })}
            />
          ) : (
            <NextgenSignup
              countries={countries}
              states={states}
              loading={loading}
              onSubmit={handleNextgenSubmit({ dispatch, setError, match })}
            />
          )}
        </React.Fragment>
      )}
    </ErrorDisplay>
  </Segment>
);

// refresh start dates onchange handler
export const handleRefreshStartDates =
  ({ programs, dispatch, match }) =>
  async (value) => {
    const program = programs.find((p) => p.value === value);
    if (program && program.scheduleId) {
      const { startdates } = await dispatch(
        loadStartDates(match.params.school, program.scheduleId)
      );
      return startdates;
    } else {
      console.log("Start Dates not updated no program or scheduleID", program);
    }
  };

// legacy signup submit handler
export const handleLegacySubmit =
  ({ dispatch, schoolId, setError }) =>
  (values) => {
    const payload = Object.assign({}, values, {
      schoolId,
    });
    dispatch(signup("/legacy/ssite/student/signup", payload)).catch((err) =>
      setError({ errorMessage: err.message })
    );
  };
// nextgen signup submit handler
export const handleNextgenSubmit =
  ({ dispatch, setError, match }) =>
  (values) => {
    const payload = Object.assign({}, values, {
      applicationName: match.params.product || "datacheck",
    });
    dispatch(signup(`/nextgen/signup/${match.params.product}`, payload)).catch(
      (err) => setError({ errorMessage: err.message })
    );
  };

Signups.propTypes = {
  schoolLoading: PropTypes.bool,
  loading: PropTypes.bool,
  match: PropTypes.object,
  corpId: PropTypes.number,
  schoolId: PropTypes.string,
  programs: PropTypes.array,
  countries: PropTypes.array,
  startdates: PropTypes.array,
  states: PropTypes.array,
  bestTimeToCall: PropTypes.array,
  dispatch: PropTypes.func,
};

Signups.displayName = "Signups";

export default Signups;
