import React from "react";
import PropTypes from "prop-types";
import { Message as MyMessage, Fade, Text, Icon } from "@jjordy/layout";

export default function Message({ onClose, content, children, header, color }) {
  return (
    <Fade visible={content || children} duration={250}>
      <MyMessage onClose={onClose} bg={color}>
        <MyMessage.Icon>
          <Icon icon="question" bg="white" size={2} />
        </MyMessage.Icon>
        <MyMessage.Content>
          <MyMessage.Header style={{ fontWeight: 700, color: "#FFF" }}>
            {header}
          </MyMessage.Header>
          <Text fg="white" strong mt={1}>
            {content || children}
          </Text>
        </MyMessage.Content>
      </MyMessage>
    </Fade>
  );
}

Message.propTypes = {
  children: PropTypes.element,
  content: PropTypes.string,
  header: PropTypes.string,
  color: PropTypes.string,
  onClose: PropTypes.func,
};
