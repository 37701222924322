import React from "react";
import { Segment, Text, Image, Header, Button } from "@jjordy/layout";
import CenterContainer from "components/Elements/CenterContainer";
import Link from "components/QueryStateLink";

export default class Marketing extends React.Component {
  render() {
    return (
      <CenterContainer
        width={450}
        pageTitle="Global Financial Aid Services - Products"
      >
        <Segment mt={4}>
          <Header textAlign="center" fg="primary">
            Select a product to get started
          </Header>
          <Image
            href="http://www.globalfas.com/solutions/datacheck"
            target="_blank"
            centered
            size="medium"
            src="https://s3.us-east-2.amazonaws.com/globalfas2/GlobalData_Logo.png"
            alt="Global Datacheck Logo"
          />
          <Header as="h4">The Basics</Header>
          <Text small fg="grey" style={{ lineHeight: 1.5 }}>
            GLOBAL DataCheck is a standalone, web-based application designed to
            automatically interface with NSLDS to retrieve each student’s loan
            and grant history, flag issues and deliver comprehensive eligibility
            results. With Global DataCheck, you can be certain that each student
            is receiving the full amount of federal aid dollars available to
            them, easily track used and remaining funds and best of all have
            more time and resources to provide exceptional student service.
          </Text>
          <Button as={Link} to="/signup/datacheck" fluid bg="primary" rounded>
            GET STARTED
          </Button>
        </Segment>
      </CenterContainer>
    );
  }
}
