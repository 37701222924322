import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { ConnectedRouter as Router } from "connected-react-router";
import configureStore from "./redux/configureStore";
import routes from "./routes";
import { renderRoutes } from "react-router-config";

const history = createBrowserHistory();
const initialState = window.__PRELOADED_STATE__;
const store = configureStore({ initialState, history, isDev: __DEV__ }); // eslint-disable-line

ReactDOM.hydrate(
  <Provider store={store}>
    <Router history={history}>{renderRoutes(routes)}</Router>
  </Provider>,
  document.getElementById("content")
);

if (module.hot) {
  module.hot.accept("./routes", () => {
    const nextRoutes = require("./routes").default;
    ReactDOM.hydrate(
      <Provider store={store}>
        <Router history={history}>{renderRoutes(nextRoutes)}</Router>
      </Provider>,
      document.getElementById("content")
    );
  });
}
