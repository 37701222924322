import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { login } from "redux/modules/login";
import WithStore from "components/WithStore";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import LegacyLoginForm from "./LegacyLoginForm";
import ErrorDisplay from "components/Errors/ErrorDisplay";
import Link from "components/QueryStateLink";

import { Segment, Header, Icon, Message, Button } from "@jjordy/layout";
import { createValidator, required } from "@jjordy/validation";

import { getCorpId } from "redux/selectors";

const validate = createValidator({
  username: [required],
  password: [required],
});

const getState = (state) => ({
  corpId: getCorpId(state),
  name: state.school.name,
  loading: state.login.loading,
});

export default function ParentLogin({ match }) {
  return (
    <CenterContainer pageTitle="PARENT LOGIN">
      <WithStore
        selector={getState}
        awaiting="corpId"
        render={({ corpId, name, loading }, dispatch) => (
          <React.Fragment>
            <Helmet title={`${name} Login`} />
            <Segment attached="top" bg="primary">
              <ClientLogo corp={corpId} />
              <Header
                children={`${name} | PARENT LOGIN`}
                fg="primary"
                as="h5"
                textAlign="center"
              />
            </Segment>
            <Segment attached>
              <Message>
                <Message.Icon>
                  <Icon icon="question" fg="red" size={2} />
                </Message.Icon>
                <Message.Content>
                  <strong>
                    If you have been provided with a 4 digit PIN to access the
                    Parent account, click{" "}
                    <Link to="/migrate/parent" fg="secondary">
                      <span style={{ textDecoration: "underline" }}>here</span>
                    </Link>{" "}
                    to
                  </strong>{" "}
                  create a username and password to comply with our new
                  username/password policy.
                  <p>
                    If you already have a username/password, enter your
                    credentials below and click Login
                  </p>
                </Message.Content>
              </Message>
            </Segment>
            <Segment attached="bottom">
              <ErrorDisplay>
                {({ setError }) => (
                  <LegacyLoginForm
                    loading={loading}
                    school={match.params.school}
                    fieldLevelProps={{
                      username: `Enter the username used to create your account.  
If you do not have a username, click “here” above to create your account.`,
                      password: `Enter the password associated with your account. 
If you do not remember your password, click FORGOT PASSWORD below.`,
                    }}
                    initialValues={{ username: "", password: "" }}
                    validations={(values, errors) => {
                      const e = validate(values);
                      return Object.assign({}, errors, e);
                    }}
                    login={(values) => {
                      dispatch(
                        login("/legacy/ssite/parent/login", values)
                      ).catch((err) => setError({ errorMessage: err.message }));
                    }}
                  />
                )}
              </ErrorDisplay>
              <Button as={Link} to="/forgot-password" mt={1}>
                FORGOT PASSWORD
              </Button>
              <Button as={Link} to="/forgot-username" mt={1}>
                FORGOT USERNAME
              </Button>
            </Segment>
          </React.Fragment>
        )}
      />
    </CenterContainer>
  );
}

ParentLogin.propTypes = {
  match: PropTypes.object.isRequired,
};
