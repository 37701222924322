import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import WithStore from "./WithStore";
import { getSchoolId } from "redux/selectors";

const getState = (state) => ({
  schoolId: getSchoolId(state),
});

export default function AwareRedirect({ to }) {
  return (
    <WithStore
      selector={getState}
      render={({ schoolId }) => {
        if (schoolId) {
          return <Redirect to={`/ssite/${schoolId}${to}`} />;
        }
        return <Redirect to={to} />;
      }}
    />
  );
}

AwareRedirect.propTypes = { to: PropTypes.string.isRequired };
