import React from "react";
import PropTypes from "prop-types";
import MigrateForm from "./Form";
import { Text, Message, Icon } from "@jjordy/layout";

export default function Step2({ onSubmit, initialValues, loading, goBack }) {
  return (
    <div>
      <Text fg="grey" strong>
        Create an account for GlobalCORE Web Staff Portal.
      </Text>
      <Text small>
        Some of your information has been imported from GlobalCORE Staff Portal
        feel free to update it as you see fit as this account will continue to
        work in tandem with your old GlobalCORE windows application credentials.
      </Text>
      <Message>
        <Message.Icon>
          <Icon icon="warning" style={{ fill: "rebeccapurple" }} />
        </Message.Icon>
        <Message.Content>
          <Text small>
            <i>
              You can continue to use the windows applcation with your old
              credentials while we transition to a web based application.
            </i>
          </Text>
        </Message.Content>
      </Message>
      <MigrateForm
        onSubmit={onSubmit}
        loading={loading}
        initialValues={initialValues}
        goBack={goBack}
      />
    </div>
  );
}

Step2.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  goBack: PropTypes.func,
};
