import React from "react";
import { Header } from "@jjordy/layout";

export default function InviteExpired() {
  return (
    <Header as="h3" color="red">
      Your invitation has expired. Please ask your administrator to resend your
      invite
    </Header>
  );
}
