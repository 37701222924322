import React from "react";
import { Segment, Header, Button, Text, Icon } from "@jjordy/layout";
import CenterContainer from "components/Elements/CenterContainer";
import ClientLogo from "components/Elements/ClientLogo";
import Link from "components/QueryStateLink";

export default function ForgotPasswordSuccess() {
  return (
    <CenterContainer pageTitle="Forgot Password">
      <Segment attached="top" bg="primary">
        <ClientLogo />
        <Header fg="primary" as="h4" textAlign="center">
          FORGOT PASSWORD SUCCESS
        </Header>
      </Segment>
      <Segment attached>
        <Text fg="grey">
          An email with instructions on how to change your password has been
          sent to your email address.
        </Text>
      </Segment>
      <Segment attached="bottom">
        <Button as={Link} to="/" bg="primary" rounded>
          <Button.Content>BACK TO LOGIN</Button.Content>
          <Button.Icon>
            <Icon icon="chevron_right" bg="white" />
          </Button.Icon>
        </Button>
      </Segment>
    </CenterContainer>
  );
}
