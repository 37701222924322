import { createSelector } from "reselect";

const schoolDataSelector = (state) => state.school;

const queryParamSelector = (state) => state.query;

const profileSelector = (state) => state.profile;

export const getSchoolId = createSelector(
  schoolDataSelector,
  (data) => data.schoolId
);

export const getCorpId = createSelector(
  schoolDataSelector,
  (data) => data.corpId
);

export const getBranchId = createSelector(
  schoolDataSelector,
  (data) => data.branchId
);

export const getQueryParams = createSelector(
  queryParamSelector,
  (data) => data
);

const allState = (state) => state;

export const anyLoading = createSelector(allState, (state) => {
  if (state.changePassword.loading) {
    return true;
  } else if (state.enumerations.loading) {
    return true;
  } else if (state.forgotPassword.loading) {
    return true;
  } else if (state.forgotPin.loading) {
    return true;
  } else if (state.forgotUsername.loading) {
    return true;
  } else if (state.invite.loading) {
    return true;
  } else if (state.login.loading) {
    return true;
  } else if (state.logout.loading) {
    return true;
  } else if (state.message.loading) {
    return true;
  } else if (state.migration.loading) {
    return true;
  } else if (state.profile.loading) {
    return true;
  } else if (state.query.loading) {
    return true;
  } else if (state.school.loading) {
    return true;
  } else if (state.signup.loading) {
    return true;
  } else if (state.theme.loading) {
    return true;
  } else if (state.twofactor.loading) {
    return true;
  } else if (state.verify.loading) {
    return true;
  }

  return false;
});

export const profileAuthorized = createSelector(profileSelector, (data) => {
  if (data) {
    if (typeof data.authorized === "boolean" && data.authorized === false) {
      return false;
    }
  }
  return true;
});
