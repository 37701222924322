import React from "react";
import PropTypes from "prop-types";

export default class ExternalRedirect extends React.Component {
  componentDidMount() {
    const { to } = this.props;
    window.location = to;
  }
  render() {
    return null;
  }
}

ExternalRedirect.propTypes = {
  to: PropTypes.string.isRequired,
};
