import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Divider } from "@jjordy/layout";
import { Formik, FastField as Field } from "formik";
import MaskedInput from "components/Forms/MaskedInput";
import {
  createValidator,
  required,
  oneNum,
  oneCap,
  oneSpecialChar,
  minLength,
  match,
  phone,
  email,
} from "@jjordy/validation";
import FormikField from "components/Forms/FormikField";
import PhoneMessage from "components/Elements/PhoneMessage";

const validate = createValidator({
  email: [email, required],
  username: [required],
  password: [required, minLength(8), oneNum, oneSpecialChar, oneCap],
  confirmPassword: [required, match("password", "Passwords")],
  phone: [required, phone],
});

export default function MigrationForm({ submit, loading, initialValues }) {
  return (
    <Formik
      onSubmit={(values) => {
        let payload = values;
        if (values.phone) {
          const cleanedPhone = values.phone.replace(/\(|\)| /g, "");
          payload = Object.assign({}, values, {
            phone: cleanedPhone,
          });
        }
        submit(payload);
      }}
      initialValues={{
        email: (initialValues && initialValues.email) || "",
        username: initialValues && initialValues.username,
        password: "",
        confirmPassword: "",
        phone: "",
      }}
      validate={(values) => {
        let errors = validate(values);
        return errors;
      }}
    >
      {({
        values,
        handleSubmit,
        handleBlur,
        touched,
        errors,
        handleChange,
      }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <Field
            component={FormikField}
            label="Username"
            disabled
            type="text"
            name="username"
            required
            placeholder="username"
          />
          <Field
            component={FormikField}
            label="Email"
            type="text"
            name="email"
            message="This is the email that will be used to retrieve your password should you forget it."
            required
            disabled={initialValues && initialValues.email}
            placeholder="email@email.com"
          />
          <Field
            component={FormikField}
            label="Password"
            type="password"
            name="password"
            required
            placeholder="********"
          />
          <Field
            component={FormikField}
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            placeholder="********"
            required
          />
          <Field
            component={FormikField}
            As={MaskedInput}
            label="Phone Number"
            name="phone"
            mask="(999) 999 9999"
            placeholder="Enter your phone number"
          />
          <Divider my={1} />
          <PhoneMessage />
          <Button
            type="submit"
            fluid
            content="Update Account"
            bg="primary"
            mt={1}
            rounded
          />
        </Form>
      )}
    </Formik>
  );
}

MigrationForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
